// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconArrowHead_root__nQKEh {\n  stroke: var(--matterColor);\n  fill: var(--matterColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconArrowHead/IconArrowHead.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wBAAwB;AAC1B","sourcesContent":[".root {\n  stroke: var(--matterColor);\n  fill: var(--matterColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconArrowHead_root__nQKEh"
};
export default ___CSS_LOADER_EXPORT___;

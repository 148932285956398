// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconKeys_strokeMarketplaceColor__1xXui {\n  stroke: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconKeys/IconKeys.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC","sourcesContent":[".strokeMarketplaceColor {\n  stroke: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strokeMarketplaceColor": "IconKeys_strokeMarketplaceColor__1xXui"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconBannedUser_backgroundLight__1A22_ {\n  stop-color: var(--bannedColorLight);\n}\n\n.IconBannedUser_backgroundDark__2BBz6 {\n  stop-color: var(--bannedColorDark);\n}\n\n.IconBannedUser_foregroundFill__680S2 {\n  fill: var(--matterColorLight);\n}\n\n.IconBannedUser_foregroundStroke__nPXCA {\n  stroke: var(--matterColorLight);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconBannedUser/IconBannedUser.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".backgroundLight {\n  stop-color: var(--bannedColorLight);\n}\n\n.backgroundDark {\n  stop-color: var(--bannedColorDark);\n}\n\n.foregroundFill {\n  fill: var(--matterColorLight);\n}\n\n.foregroundStroke {\n  stroke: var(--matterColorLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundLight": "IconBannedUser_backgroundLight__1A22_",
	"backgroundDark": "IconBannedUser_backgroundDark__2BBz6",
	"foregroundFill": "IconBannedUser_foregroundFill__680S2",
	"foregroundStroke": "IconBannedUser_foregroundStroke__nPXCA"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserNav_root__MukAU {\n  display: none\n}\n\n@media (min-width: 768px) {\n\n.UserNav_root__MukAU {\n    display: flex;\n    justify-content: flex-end;\n    height: 57px;\n    align-items: flex-end;\n    padding: 13px 24px 0 24px\n}\n  }\n\n@media (min-width: 1024px) {\n\n.UserNav_root__MukAU {\n    padding: 0 36px\n}\n  }\n\n.UserNav_tab__3t1t9 {\n  display: flex;\n  align-items: flex-end;\n  height: 100%\n}\n\n.UserNav_tab__3t1t9:first-child {\n    margin-left: 0;\n  }\n", "",{"version":3,"sources":["webpack://src/components/UserNav/UserNav.module.css"],"names":[],"mappings":"AAAA;EACE;AAaF;;AAXE;;AAHF;IAII,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB;AAMJ;EALE;;AAEA;;AAXF;IAYI;AAEJ;EADE;;AAGF;EACE,aAAa;EACb,qBAAqB;EACrB;AAKF;;AAHE;IACE,cAAc;EAChB","sourcesContent":[".root {\n  display: none;\n\n  @media (--viewportMedium) {\n    display: flex;\n    justify-content: flex-end;\n    height: 57px;\n    align-items: flex-end;\n    padding: 13px 24px 0 24px;\n  }\n\n  @media (--viewportLarge) {\n    padding: 0 36px;\n  }\n}\n\n.tab {\n  display: flex;\n  align-items: flex-end;\n  height: 100%;\n\n  &:first-child {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "UserNav_root__MukAU",
	"tab": "UserNav_tab__3t1t9"
};
export default ___CSS_LOADER_EXPORT___;

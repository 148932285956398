import { gql } from "@apollo/client";

const Get_Listings = gql(`
query GetListings($pageNumber: Int) {
  getListings(pageNumber: $pageNumber) {
    _id
    showListing
    listingUuid
  }
}`);

const Get_Local_Listing = gql(`
  query Query {
  getLocalListing {
    _id
    listingUuid
    showListing
  }
}`)



export {
  Get_Listings,
  Get_Local_Listing
};
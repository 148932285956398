// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconCopy_root__1Eb8w {\n    fill: var(--marketplaceColor);\n  }\n  ", "",{"version":3,"sources":["webpack://src/components/IconCopy/IconCopy.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;EAC/B","sourcesContent":[".root {\n    fill: var(--marketplaceColor);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconCopy_root__1Eb8w"
};
export default ___CSS_LOADER_EXPORT___;

/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './PropertyGroup.module.css';

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const Item = props => {
  const { label, isSelected, price, inputType, unitType, intl } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  const { formattedPrice } = priceData(new Money(price, 'USD'), intl);

  const isPerHour = unitType === "perHour";

  const unitTranslationKey = isPerHour
    ? 'ListingPage.perHour'
    : 'ListingPage.oneTime';

  const serviceTypeTranslationKey = inputType === 'optional';

  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        <IconCheck isVisible={isSelected} />
      </span>
      <div className={css.labelWrapper}>
        <div className={css.labelTop}>
          <span className={labelClass}>
            {label} 
            {inputType === "optional" ? <FormattedMessage id="ListingPage.optional" /> : null}
          </span>
          <span className={css.inputType}>{formattedPrice + " "} 
            <FormattedMessage id={unitTranslationKey} /></span>
        </div>
      </div>
    </li>
  );
};

const AdditionalPropertyGroup = props => {
  const { rootClassName, className, id, options, twoColumns, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  return (
    <ul className={listClasses}>
      {options.map(option => (
        <Item 
          key={`${id}.${option.nameInput}`} 
          label={option.nameInput} 
          price={option.price} 
          inputType={option.inputType}
          unitType={option.unitType}
          isSelected={true} 
          intl={intl}
        />
      ))}
    </ul>
  );
};

AdditionalPropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

AdditionalPropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default AdditionalPropertyGroup;

import React from 'react';
import { FieldSelect } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingCategoryForm.module.css';

const CustomCertificateSelectFieldMaybe = props => {
  const { name, id, spaceTypeOptions, intl } = props;
  const certificateLabel = intl.formatMessage({
    id: 'EditListingCategoryForm.categoryLabel',
  });

  const requiredCategory = intl.formatMessage({
    id: 'EditListingCategoryForm.requiredCategory',
  });

  return spaceTypeOptions ? (
    <FieldSelect className={css.certificate} name={name} id={id} label={certificateLabel} validate={required(requiredCategory)}>
      {spaceTypeOptions.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomCertificateSelectFieldMaybe;

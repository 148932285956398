import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, NamedLink } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        userName,
        values,
        accountTypes
      } = formRenderProps;

      const translationKey = 'EditListingPricingForm.pricing';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const greetingMessage = (
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingPricingForm.greetings"
            values={{
              name: (<strong> {userName}</strong>),
              b: (crunk) => <b>{crunk}</b>
            }}
          />
        </p>
      );

      const unitPerHour = intl.formatMessage({
        id: 'EditListingPricingForm.perUnit',
      });

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.formContent}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            {greetingMessage}

            <p>
              <FormattedMessage id="EditListingPricingForm.tipAccountTypes" />
            </p>

            <div className={css.accountTypes}>
              {accountTypes.map((accountType, i) => {

                if (!accountType.custom_pricing) return null;

                return (
                  <FieldCurrencyInput
                    id={`price_${accountType.key}`}
                    name={`price_${accountType.key}`}
                    className={classNames(css.priceInput, css.accountTypePriceInput)}
                    key={i}
                    label={accountType.short_label}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    unit={unitPerHour}
                  />
                )
              })}
            </div>
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              autoFocus
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              unit={unitPerHour}
              validate={priceValidators}
            />
            <p className={css.tip}>
              <FormattedMessage id="EditListingPricingForm.tip1" />
            </p>

            <p className={css.tip}>
              <FormattedMessage id="EditListingPricingForm.tip2" />
            </p>
          </div>

          <div className={css.formActions}>
            <NamedLink name="LandingPage" className={css.closeLink}>
              <FormattedMessage id="EditListingWizard.closeAndSaveButton" />
            </NamedLink>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconReviewStar_root__2u_m3 {\n  fill: var(--matterColorNegative);\n}\n\n.IconReviewStar_filled__18gGI {\n  fill: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconReviewStar/IconReviewStar.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".root {\n  fill: var(--matterColorNegative);\n}\n\n.filled {\n  fill: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconReviewStar_root__2u_m3",
	"filled": "IconReviewStar_filled__18gGI"
};
export default ___CSS_LOADER_EXPORT___;

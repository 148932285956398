// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconEnquiry_root__LY0Wg {\n}\n\n.IconEnquiry_marketplaceColorStroke__1Snws {\n  stroke: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconEnquiry/IconEnquiry.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".root {\n}\n\n.marketplaceColorStroke {\n  stroke: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconEnquiry_root__LY0Wg",
	"marketplaceColorStroke": "IconEnquiry_marketplaceColorStroke__1Snws"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldDateInput_fieldRoot__bDeH0 {\n}\n\n.FieldDateInput_pickerSuccess__3Jy1Z .DateInput_input {\n    border-bottom-color: var(--successColor);\n    color: var(--matterColor);\n  }\n\n.FieldDateInput_pickerError__16vjr .DateInput_input {\n    border-bottom-color: var(--failColor);\n  }\n\n.FieldDateInput_labelDisabled__HN8PQ {\n  color: var(--matterColorNegative);\n}\n\n/**\n * Mobile margins mean that labels, inputs (incl separate borders) get margins,\n * but calendar popup doesn't.\n */\n\n.FieldDateInput_mobileMargins__1piyD {\n  width: calc(100% - 48px);\n  margin: 0 24px\n}\n\n@media (min-width: 768px) {\n\n.FieldDateInput_mobileMargins__1piyD {\n    width: 100%;\n    margin: 0\n}\n  }\n", "",{"version":3,"sources":["webpack://src/components/FieldDateInput/FieldDateInput.module.css"],"names":[],"mappings":"AAAA;AACA;;AAGE;IACE,wCAAwC;IACxC,yBAAyB;EAC3B;;AAIA;IACE,qCAAqC;EACvC;;AAGF;EACE,iCAAiC;AACnC;;AAEA;;;EAGE;;AACF;EACE,wBAAwB;EACxB;AAMF;;AAJE;;AAJF;IAKI,WAAW;IACX;AAEJ;EADE","sourcesContent":[".fieldRoot {\n}\n\n.pickerSuccess {\n  & :global(.DateInput_input) {\n    border-bottom-color: var(--successColor);\n    color: var(--matterColor);\n  }\n}\n\n.pickerError {\n  & :global(.DateInput_input) {\n    border-bottom-color: var(--failColor);\n  }\n}\n\n.labelDisabled {\n  color: var(--matterColorNegative);\n}\n\n/**\n * Mobile margins mean that labels, inputs (incl separate borders) get margins,\n * but calendar popup doesn't.\n */\n.mobileMargins {\n  width: calc(100% - 48px);\n  margin: 0 24px;\n\n  @media (--viewportMedium) {\n    width: 100%;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldRoot": "FieldDateInput_fieldRoot__bDeH0",
	"pickerSuccess": "FieldDateInput_pickerSuccess__3Jy1Z",
	"pickerError": "FieldDateInput_pickerError__16vjr",
	"labelDisabled": "FieldDateInput_labelDisabled__HN8PQ",
	"mobileMargins": "FieldDateInput_mobileMargins__1piyD"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { required } from '../../util/validators';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { Button, Form, FieldCurrencyInput, NamedLink, FieldTextInput, Modal, FieldSelect, IconClose } from '../../components';
import css from './EditListingServicesForm.module.css';
import CustomServices from './CustomServicesInput';
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

export const EditListingServicesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators
    }}
    render={formRenderProps => {
      const {
        form,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        form: {
          mutators: { push, pop }
        },
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        userName,
        onManageDisableScrolling,
      } = formRenderProps;

      const camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      }

      const cleaningFeeMessage = intl.formatMessage({
        id: 'EditListingServicesForm.cleaningFee',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const [openModal, setOpenModal] = useState(false);

      const greetingMessage = (
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingServicesForm.greeting"
            values={{
              name: (<strong> {userName}</strong>),
              b: (crunk) => <b>{crunk}</b>
            }}
          />
        </p>
      );

      const unitPerHour = intl.formatMessage({
        id: 'EditListingServicesForm.perUnit',
      });

      const unitOneTime = intl.formatMessage({
        id: 'EditListingServicesForm.oneTime',
      });

      const optionalLabel = intl.formatMessage({
        id: 'EditListingServicesForm.optional',
      });

      const requiredLabel = intl.formatMessage({
        id: 'EditListingServicesForm.required',
      });
      
      return (
        <>
          <Form onSubmit={handleSubmit} className={classes}>
            <div className={css.formContent}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingServicesForm.updateFailed" />
                </p>
              ) : null}
              {showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingServicesForm.showListingFailed" />
                </p>
              ) : null}
              {greetingMessage}
              <div className={css.inputs}>

                <FieldArray
                  name="customServices"
                >
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name}>
                        <div className={css.customServices}>
                          <FieldTextInput
                            name={`${name}.nameInput`}
                            className={css.priceInput}
                            inputRootClass={css.nameInput}
                            type="text"
                            placeholder="Name"
                            validate={required(requiredLabel)}
                          />
                          <FieldSelect
                            name={`${name}.inputType`}
                            className={css.unitInput}
                            selectClassName={css.inputType}
                          >
                            <option value="optional">{optionalLabel}</option>
                            <option value="required">{requiredLabel}</option>
                          </FieldSelect>
                          {index > 4 ? (
                            <button className={css.deleteButton} type="button" onClick={() => fields.remove(index)}>
                            <IconClose />
                          </button>
                          ) : null}
                        </div>
                        <div className={css.customServicesDark}>
                          <FieldCurrencyInput
                            name={`${name}.price`}
                            className={css.priceInput}
                            currencyConfig={config.currencyConfig}
                            validate={required(requiredLabel)}
                          />
                          <FieldSelect
                            name={`${name}.unitType`}
                            className={css.unitInput}
                            selectClassName={css.unitTypeInput}
                          >
                            <option value="perHour">{unitPerHour}</option>
                            <option value="oneTime">{unitOneTime}</option>
                          </FieldSelect>
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
                
              </div>
              <Button
                type="button"
                onClick={() => {
                  push('customServices', { unitType: "perHour", inputType: "optional" })
                }}
                className={css.button}
              >
                <FormattedMessage id="EditListingServicesForm.addService" />
              </Button>
            </div>

            <div className={css.formActions}>
              <NamedLink name="LandingPage" className={css.closeLink}>
                <FormattedMessage id="EditListingWizard.closeAndSaveButton" />
              </NamedLink>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
          <Modal
            id="addServiceModal"
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            className={css.modal}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.formContent}>
              <FieldTextInput
                id="serviceName"
                name="serviceName"
                type="text"
                className={css.priceInputModal}
                autoFocus
                label={intl.formatMessage({
                  id: 'EditListingServicesForm.serviceName',
                })}
                placeholder={intl.formatMessage({
                  id: 'EditListingServicesForm.serviceNamePlaceholder',
                })}
              />
              <CustomServices
                  priceName="servicePrice"
                  unitName="serviceUnityType"
                  priceClassName={css.priceInput}
                  unitClassName={css.unitInput}
                  priceUnitTypeClassName={css.unitTypeInput}
                  currencyConfig={config.currencyConfig}
                  unitPerHourLabel={unitPerHour}
                  unitOneTimeLabel="One Time"
                  label={cleaningFeeMessage}
                />
              <Button
                type="button"
                onClick={() => {
                  push('customServices', undefined)
                }}
                className={css.buttonModal}
              >
                <FormattedMessage id="EditListingServicesForm.addService" />
              </Button>
            </div>
          </Modal>
        </>
      );
    }}
  />
);

EditListingServicesFormComponent.defaultProps = { fetchErrors: null };

EditListingServicesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingServicesFormComponent);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconSpinner_root__1h99J {\n  /* While the viewbox of the icon is 30x30, the icon size is scaled to\n   28x28 by default, which results in scaling the whole image and the\n   strokes a bit.*/\n  width: 28px;\n  height: 28px;\n\n  stroke: var(--marketplaceColor);\n  stroke-width: 3px;\n}\n", "",{"version":3,"sources":["webpack://src/components/IconSpinner/IconSpinner.module.css"],"names":[],"mappings":"AAAA;EACE;;kBAEgB;EAChB,WAAW;EACX,YAAY;;EAEZ,+BAA+B;EAC/B,iBAAiB;AACnB","sourcesContent":[".root {\n  /* While the viewbox of the icon is 30x30, the icon size is scaled to\n   28x28 by default, which results in scaling the whole image and the\n   strokes a bit.*/\n  width: 28px;\n  height: 28px;\n\n  stroke: var(--marketplaceColor);\n  stroke-width: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconSpinner_root__1h99J"
};
export default ___CSS_LOADER_EXPORT___;

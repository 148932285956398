import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { EditListingDescriptionForm } from '../../forms';
import { IconArrowHead } from '../../components';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onNextTab,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title } = currentListing.attributes;

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepActive}>
          <FormattedMessage 
            id="EditListingDescriptionPanel.currentStep" 
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingDescriptionPanel.nextStep" />
        </div>
        <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
      </div>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ title, description }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description } = values;
          const updateValues = {
            title: title.trim(),
            description,
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        currentUser={currentUser}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;

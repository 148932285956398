// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StripePaymentAddress_root__yY89e {\n  display: flex;\n  flex-direction: column;\n}\n\n.StripePaymentAddress_paymentAddressField__3weNc {\n  padding-top: 38px;\n}\n\n.StripePaymentAddress_formRow__syhPP {\n  /* This container uses flexbox layout */\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  /* parent aka root is flexbox, this container takes all available space */\n  flex-grow: 1;\n  flex-shrink: 0;\n  flex-wrap: wrap\n}\n\n@media (min-width: 550px) {\n\n.StripePaymentAddress_formRow__syhPP {\n    flex-wrap: no-wrap\n}\n  }\n\n.StripePaymentAddress_field__1ZCeu {\n  width: 100%;\n  margin-top: 24px\n}\n\n@media (min-width: 550px) {\n\n.StripePaymentAddress_field__1ZCeu {\n    width: calc(50% - 12px)\n}\n  }\n", "",{"version":3,"sources":["webpack://src/components/StripePaymentAddress/StripePaymentAddress.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uCAAuC;EACvC,aAAa;EACb,8BAA8B;EAC9B,WAAW;;EAEX,yEAAyE;EACzE,YAAY;EACZ,cAAc;EACd;AAKF;;AAHE;;AAXF;IAYI;AAEJ;EADE;;AAGF;EACE,WAAW;EACX;AAKF;;AAHE;;AAJF;IAKI;AAEJ;EADE","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.paymentAddressField {\n  padding-top: 38px;\n}\n\n.formRow {\n  /* This container uses flexbox layout */\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n\n  /* parent aka root is flexbox, this container takes all available space */\n  flex-grow: 1;\n  flex-shrink: 0;\n  flex-wrap: wrap;\n\n  @media (--viewportSmall) {\n    flex-wrap: no-wrap;\n  }\n}\n\n.field {\n  width: 100%;\n  margin-top: 24px;\n\n  @media (--viewportSmall) {\n    width: calc(50% - 12px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "StripePaymentAddress_root__yY89e",
	"paymentAddressField": "StripePaymentAddress_paymentAddressField__3weNc",
	"formRow": "StripePaymentAddress_formRow__syhPP",
	"field": "StripePaymentAddress_field__1ZCeu"
};
export default ___CSS_LOADER_EXPORT___;

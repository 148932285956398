// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconSuccess_root__2ZnkE {\n}\n\n.IconSuccess_fillColor__2CXJX {\n  fill: var(--successColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconSuccess/IconSuccess.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".root {\n}\n\n.fillColor {\n  fill: var(--successColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconSuccess_root__2ZnkE",
	"fillColor": "IconSuccess_fillColor__2CXJX"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchMap_root__3vvuD {\n  width: 100%;\n  height: 100%;\n  background-color: var(--matterColorNegative);\n}\n\n.SearchMap_mapRoot__3iBS0 {\n  width: 100%;\n  height: 100%;\n}\n\n.SearchMap_reusableMap__1EORj {\n  width: 100%;\n  height: 100%;\n}\n\n.SearchMap_defaultMapLayout__1iVc1 {\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 50vw;\n  height: 100vh;\n}\n\n/**\n * When reusable map is attached right to the body it's hidden.\n * Specificity rule is added to overwrite positioning coming from props.className\n */\n\nbody > .SearchMap_reusableMapHidden__y5hMJ {\n  position: absolute;\n  top: -1000px;\n  left: -1000px;\n  visibility: hidden;\n  opacity: 0\n}\n\n@media (min-width: 768px) {\n\nbody > .SearchMap_reusableMapHidden__y5hMJ {\n    top: -1000px;\n    left: -1000px;\n    right: auto\n}\n  }\n", "",{"version":3,"sources":["webpack://src/components/SearchMap/SearchMap.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,WAAW;EACX,aAAa;AACf;;AAEA;;;EAGE;;AACF;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB;AAOF;;AALE;;AAPF;IAQI,YAAY;IACZ,aAAa;IACb;AAEJ;EADE","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  background-color: var(--matterColorNegative);\n}\n\n.mapRoot {\n  width: 100%;\n  height: 100%;\n}\n\n.reusableMap {\n  width: 100%;\n  height: 100%;\n}\n\n.defaultMapLayout {\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 50vw;\n  height: 100vh;\n}\n\n/**\n * When reusable map is attached right to the body it's hidden.\n * Specificity rule is added to overwrite positioning coming from props.className\n */\nbody > .reusableMapHidden {\n  position: absolute;\n  top: -1000px;\n  left: -1000px;\n  visibility: hidden;\n  opacity: 0;\n\n  @media (--viewportMedium) {\n    top: -1000px;\n    left: -1000px;\n    right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SearchMap_root__3vvuD",
	"mapRoot": "SearchMap_mapRoot__3iBS0",
	"reusableMap": "SearchMap_reusableMap__1EORj",
	"defaultMapLayout": "SearchMap_defaultMapLayout__1iVc1",
	"reusableMapHidden": "SearchMap_reusableMapHidden__y5hMJ"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { IconArrowHead } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';


import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onNextTab,
    onPreviousTab
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;

  const userName = currentUser ? currentUser.attributes.profile.firstName : '';

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const accountTypes = findOptionsForSelectFilter('accountType', config.custom.userAccountType)

  const formatCustomPrice = () => {
    var customPrices = {}
    accountTypes.forEach((accountType) => {
      const element = currentListing.attributes.publicData["price_"+accountType.key]
      if (element) {
        customPrices["price_"+accountType.key] = new Money(element.amount, element.currency)
      }
    })
    return customPrices
  }

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price, ...formatCustomPrice() }}
      onSubmit={(values) => {
        const { 
          price,
          ...rest
        } = values;

        var customPrices = {}
        accountTypes.forEach((accountType) => {
          const element = rest["price_"+accountType.key]
          if (element) {
            customPrices["price_"+accountType.key] = { amount: element.amount || 0, currency: element.currency || config.currency}
          }
        })

        const updateValues = {
          price,
          publicData: {
            ...customPrices
          }
        };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      userName={userName}
      accountTypes={accountTypes}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.steps}>
      <button type='button' className={css.backButton} onClick={() => {
            onPreviousTab();
          }}>
            <IconArrowHead direction={"left"} isBig={true}/>
          </button>
        <div className={css.stepActive}>
          <FormattedMessage 
            id="EditListingPricingPanel.currentStep" 
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingPricingPanel.nextStep" />
        </div>
        <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
      </div>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;

import React, { Component, useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton, IconAdd } from '../../components';
import { propTypes } from '../../util/types';
import { Storage } from 'aws-amplify';

import css from './SendMessageForm.module.css';
import { configureAWS } from '../../amplify';

// TODO: Separate this to an api request
const BLUR_TIMEOUT_MS = 100;
const sharetribeSdk = require('sharetribe-flex-sdk');
const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');
const clientId = process.env.REACT_APP_INTEGRATION_ID;
const clientSecret = process.env.REACT_APP_INTEGRATION_SECRET;

const integrationSdk = flexIntegrationSdk.createInstance({
    clientId,
    clientSecret
});

async function getCurrentUser() {
  const res = await sdk.currentUser.show();
  return res.data.data;
}

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
});

const uploadFiles = async (file) => {
  await Storage.put(`${file.name}`, file, {
    resumable: true,
    completeCallback: (event) => {
      console.log(event);
      const { key } = event
      const str = key.replace(/\s+/g, "+");
      const messageData = process.env.REACT_APP_AWS_URL + '/public/'+str;
      const currentLocation = window.location.href;
      const transaction = currentLocation.split("/")[4];
      sdk.messages.send({
        transactionId: transaction,
        content: messageData
        },
        sdk.transactions.show({
          id: transaction,
          include: 'provider, customer'
        }).then(res => {
          const customer = res.data.data.relationships.customer.data.id.uuid;
          const provider = res.data.data.relationships.provider.data.id.uuid;
          getCurrentUser().then(currentUser =>{
            const notificationMessageUser = currentUser.id.uuid === customer ? 'provider' : 'customer'
              integrationSdk.users.show({
                id: notificationMessageUser === 'provider' ? provider : customer
              }).then(res => {
                var originalArray = res.data.data.attributes.profile.privateData.test ? res.data.data.attributes.profile.privateData.test : [];
                const messagesUpdated = [...originalArray, transaction];
                integrationSdk.users.updateProfile({
                  id: notificationMessageUser === 'provider' ? provider : customer,
                  privateData: {
                    test: messagesUpdated
                  }
                }).then(res => {
                  window.location.reload()
                })
              });
          })
        })
        )
    },
    errorCallback: (err) => {
      console.log('error', err)
    }
  })
}


const IconSendMessage = () => {
  useEffect(() => {
    configureAWS()
    }, [])

  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={css.strokeMatter} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    getCurrentUser().then((currentUser) => {
      //console.log(currentUser.attributes.profile.privateData.test.messages);
      const filteredArray = currentUser?.attributes?.profile?.privateData?.test?.filter(element => {
        const currentLocation = window.location.href;
        const transaction = currentLocation.split("/")[4];
        return element !== transaction;
      });
      sdk.currentUser.updateProfile({
        privateData: {
            test: filteredArray
        }
      })
    }).catch((error) => {
      console.error(error);
    });

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          const ACCEPT_FILES = ".pdf,.txt,.doc,.docx,.jpg,.jpeg,.png,.gif,.xlsx";
          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.messageInput}>
                <FieldTextInput
                  className={css.messageInputField}
                  inputRootClass={css.textarea}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder={messagePlaceholder}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                />
                <div className={css.buttonsContainer}>
                  <Field
                    id="addImage"
                    name="addImage"
                    accept={ACCEPT_FILES}
                    form={null}
                    type="file"
                    disabled={false}
                  >
                    {fieldprops => {
                      const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = async e => {
                        const file = e.target.files[0];

                        if (file) {
                          uploadFiles(file)
                        }
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                          <div className={css.aspectRatioWrapper}>
                            <span className={css.labelFile}>
                              <IconAdd className={css.iconFile} />
                              <span className={css.spanUploadFile}>Upload File</span>
                            </span>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                      );
                    }}
                  </Field>
                  <SecondaryButton
                    id="SendMessageButton"
                    rootClassName={css.submitButton}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  >
                    <IconSendMessage />
                    <span className={css.submitButtonText}><FormattedMessage id="SendMessageForm.sendMessage" /></span>
                  </SecondaryButton>
                </div>
              </div>
              <div className={css.submitContainer}>
                <div className={css.errorContainer}>
                  {sendMessageError ? (
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  ) : null}
                </div>

              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs_root__1Lwap {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  flex: 1 1;\n}\n\n.Tabs_imageContainer__1tuiL {\n  display: none\n}\n\n@media (min-width: 1024px) {\n\n.Tabs_imageContainer__1tuiL {\n    display: block\n}\n  }\n\n.Tabs_image__2gK_k {\n  width: 100%;\n  height: 920px;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://src/components/Tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,SAAO;AACT;;AAEA;EACE;AAKF;;AAHE;;AAHF;IAII;AAEJ;EADE;;AAGF;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  flex: 1;\n}\n\n.imageContainer {\n  display: none;\n\n  @media (--viewportLarge) {\n    display: block;\n  }\n}\n\n.image {\n  width: 100%;\n  height: 920px;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Tabs_root__1Lwap",
	"imageContainer": "Tabs_imageContainer__1tuiL",
	"image": "Tabs_image__2gK_k"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, NamedLink } from '../../components';

import Popup from 'reactjs-popup';

import css from './EditListingDescriptionForm.module.css';

import helpIcon from '../../assets/helpIcon.png';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentUser
      } = formRenderProps;

      const userName = currentUser ? currentUser.attributes.profile.firstName : '';

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionTagMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionTag',
      });
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description'
      },{
        tag: <span className={css.inactive}>{descriptionTagMessage}</span>
      });

      const desctiptionToolTipMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionToolTip'
      });

      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const greetingMessage = (
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingDescriptionForm.greeting"
            values={{
              name: (<strong> {userName}</strong>),
              b: (crunk) => <b>{crunk}</b>
            }}
          />
        </p>
      )

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div id="formContent" className={css.formContent}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            {greetingMessage}
            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={
                <div className={css.descriptionLabel}>
                  <span>{descriptionMessage}</span>
                  <Popup 
                    trigger={
                      <div className={css.helpButton}> 
                        <img src={helpIcon} alt="Tool tip" width="24px" />
                      </div>
                    } 
                    on={['hover', 'focus']} 
                    position="right center" 
                    keepTooltipInside="#formContent"
                  >
                    <div className={css.popup}>
                      {desctiptionToolTipMessage}
                    </div>
                  </Popup>
                </div>
              }
              placeholder={descriptionPlaceholderMessage}
              rows="5"
              validate={composeValidators(required(descriptionRequiredMessage))}
            />
          </div>

          <div className={css.formActions}>
            <NamedLink name="LandingPage" className={css.closeLink}>
              <FormattedMessage id="EditListingWizard.closeButton" />
            </NamedLink>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);

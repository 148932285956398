import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { EditListingCategoryForm } from '../../forms';
import { IconArrowHead } from '../../components';
import config from '../../config';

import css from './EditListingCategoryPanel.module.css';

const EditListingCategoryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onNextTab,
    onPreviousTab
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const userName = currentUser ? currentUser.attributes.profile.firstName : '';

  const spaceTypeOptions = findOptionsForSelectFilter('spaceType', config.custom.filters);
  
  return (
    <div className={classes}>
      <div className={css.steps}>
        <button type='button' className={css.backButton} onClick={() => {
            onPreviousTab();
          }}>
            <IconArrowHead direction={"left"} isBig={true}/>
          </button>
        <div className={css.stepActive}>
          <FormattedMessage 
            id="EditListingCategoryPanel.currentStep" 
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingCategoryPanel.nextStep" />
        </div>
        <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
      </div>
      <EditListingCategoryForm
        className={css.form}
        initialValues={{ spaceType: publicData.spaceType }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { spaceType } = values;
          const updateValues = {
            publicData: { spaceType },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        spaceTypeOptions={spaceTypeOptions}
        userName={userName}
      />
    </div>
  );
};

EditListingCategoryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingCategoryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCategoryPanel;

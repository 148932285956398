import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape } from '../../util/reactIntl';
import { DeleteFilled } from '@ant-design/icons';
import { FormattedMessage } from '../../util/reactIntl';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.module.css';
import { Divider, Select } from 'antd';
import { clearData } from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';
// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();
const startSlots = "startSlots"
const endSlots = "endSlots"

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }

  return getEndHours(intl, timeZone, startLimit, endLimit);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone)
      );

  // Value selectedStartTime is a string when user has selected it through the form.
  // That's why we need to convert also the timestamp we use as a default
  // value to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp.toString()
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeSlot = timeSlots.find(t =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  const endTimes = getAvailableEndTimes(intl, timeZone, startTime, endDate, selectedTimeSlot);

  // We need to convert the timestamp we use as a default value
  // for endTime to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp
      ? endTimes[0].timestamp.toString()
      : null;

  return { startTime, endDate, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      currentIndex: 0,
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone, selectedIndex } = this.props;
    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = (value, index) => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      values,
      selectedIndex,
      formNumber,
    } = this.props;
    selectedIndex(index);
    this.setState(prevState => ({
      shouldDisplay: {
        ...prevState.shouldDisplay,
        [index]: true,
      },
    }));
    if (!value || !value.date) {
      form.batch(() => {
        form.change(`booking[${index}].bookingStartTime`, null);
        form.change(`booking[${index}].bookingEndDate`, { date: null });
        form.change(`booking[${index}].bookingEndTime`, null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });

      return;
    }
    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { startTime, endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate
    );

    form.batch(() => {
      form.change(`booking[${index}].bookingStartTime`, startTime);
      form.change(`booking[${index}].bookingEndDate`, { date: endDate });
      form.change(`booking[${index}].bookingEndTime`, endTime);
    });

    this.setState(prevState => ({
      currentIndex: index,
      [index]: {
        startDate: startDate.toString(),
        startTime: startTime,
        endTime: endTime,
        endDate: endDate,
      },
    }));
  };

  onBookingStartTimeChange = (value, index) => {
    const { monthlyTimeSlots, timeZone, intl, form, values, selectedIndex } = this.props;
    selectedIndex(index);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.booking[index].bookingStartDate.date;
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    const { endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value
    );

    form.batch(() => {
      form.change(`booking[${index}].bookingEndDate`, { date: endDate });
      form.change(`booking[${index}].bookingEndTime`, endTime);
    });

    this.setState(prevState => ({
      currentIndex: index,
      [index]: {
        startDate: prevState[index].startDate,
        startTime: value,
        endTime: endTime,
        endDate: endDate,
      },
    }));
  };

  onBookingEndTimeChange = (value, index) => {
    this.props.selectedIndex(index);
    this.setState(prevState => ({
      currentIndex: index,
      [index]: {
        startDate: prevState[index].startDate,
        startTime: prevState[index].startTime,
        endDate: prevState[index].endDate,
        endTime: value,
      },
    }));
  };

  onBookingEndDateChange = (value, index) => {
    const { monthlyTimeSlots, timeZone, intl, form, values } = this.props;
    this.setState({ currentIndex: index });
    if (!value || !value.date) {
      form.change(`booking[${index}].bookingEndTime`, null);
      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const { bookingStartDate, bookingStartTime } = values.booking[index];
    const startDate = bookingStartDate.date;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      bookingStartTime,
      endDate
    );

    form.change(`booking[${index}].bookingEndTime`, endTime);

    this.setState(prevState => ({
      currentIndex: index,
      [index]: {
        startDate: prevState[index].startDate,
        startTime: prevState[index].startTime,
        endTime: endTime,
        endDate: endDate,
      },
    }));
  };

  onAdditionalServiceChange = (e, index) => {
    const { form } = this.props;
    this.props.selectedIndex(index);
    console.log(e);
    form.change(`booking[${index}].extraServices`, e);

    this.setState(prevState => ({
      currentIndex: index,
      [index]: {
        startDate: prevState[index].startDate,
        startTime: prevState[index].startTime,
        endTime: prevState[index].endTime,
        endDate: prevState[index].endDate,
      },
    }));
  };

  isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  }

  getUpdateSlots = (
    intl,
    monthlyTimeSlots,
    timeZone,
    bookingStartDate,
    bookingEndDate,
    getMonthlyTimeSlots,
    getTimeSlots,
    getAvailableStartTimes,
    getAllTimeValues,
    getAvailableEndTimes,
    index
  ) => {
    let timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );

    let timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      timeZone
    );

    let availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate
    );

    let firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    let { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      firstAvailableStartTime,
      bookingEndDate
    );

    let availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      startTime,
      bookingEndDate || endDate,
      selectedTimeSlot
    );

    // let availableStartTimesSlots =  {
    //   [index] : availableStartTimes
    // }

    // let existingSlot = JSON.parse(sessionStorage.getItem("slots"))

    // if(Object.keys(availableStartTimes).length > 0){
    //   sessionStorage.setItem('slots', !!existingSlot && Object.keys(existingSlot).length>0 ?  JSON.stringify({...existingSlot , ...availableStartTimesSlots}) : JSON.stringify(availableStartTimesSlots))
    // }

    // if(Object.keys(availableStartTimes).length === 0){
    //   availableStartTimes = JSON.parse(sessionStorage.getItem("slots"))[index]
    //   console.log(availableStartTimes)
    // }

    availableStartTimes = this.updatedSlots(availableStartTimes, availableEndTimes, index)
      .StartTimes;
    availableEndTimes = this.updatedSlots(availableStartTimes, availableEndTimes, index).EndTimes;
    
    return { availableStartTimes, availableEndTimes };
  };

  // getEndTimeSlot = (
  //   availableStartTimes,
  //   bookingStartDate,
  //   timeSlotsOnSelectedDate,
  //   intl,
  //   timeZone,
  //   getAllTimeValues,
  //   getAvailableEndTimes
  // ) => {
  //   let firstAvailableStartTime =
  //     availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
  //       ? availableStartTimes[0].timestamp
  //       : null;

  //   let { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
  //     intl,
  //     timeZone,
  //     timeSlotsOnSelectedDate,
  //     bookingStartDate,
  //     bookingStartTime || firstAvailableStartTime,
  //     bookingEndDate || bookingStartDate
  //   );

  //   let availableEndTimes = getAvailableEndTimes(
  //     intl,
  //     timeZone,
  //     bookingStartTime || startTime,
  //     bookingEndDate || endDate,
  //     selectedTimeSlot
  //   );

  //   return availableEndTimes;
  // };

  updateState(key) {
    let newState = { ...this.state };
    delete newState[key];
    let numberState = {};
    let stringState = {};
    let index = 0;
    for (const key in newState) {
      if (!isNaN(+key)) {
        numberState = {
          [index]: newState[key],
          ...numberState,
        };
        index++;
      } else {
        stringState = {
          [key]: newState[key],
          ...stringState,
        };
        index--;
      }
    }
    newState = { ...numberState, ...stringState };
    this.setState(newState);
  }

  updatedSlots(StartTimes, EndTimes, index) {
    let availableStartTimesSlots = {
      [index]: StartTimes,
    };

    let availableEndTimesSlots = {
      [index]: EndTimes,
    };

    let existingStartSlot = JSON.parse(sessionStorage.getItem(startSlots));
    let existingEndSlot = JSON.parse(sessionStorage.getItem(endSlots));

    if (Object.keys(StartTimes).length > 0) {
      sessionStorage.setItem(
        startSlots,
        !!existingStartSlot && Object.keys(existingStartSlot).length > 0
          ? JSON.stringify({ ...existingStartSlot, ...availableStartTimesSlots })
          : JSON.stringify(availableStartTimesSlots)
      );
    }

    if (Object.keys(EndTimes).length > 0) {
      sessionStorage.setItem(
        endSlots,
        !!existingEndSlot && Object.keys(existingEndSlot).length > 0
          ? JSON.stringify({ ...existingEndSlot, ...availableEndTimesSlots })
          : JSON.stringify(availableEndTimesSlots)
      );
    }

    if (Object.keys(StartTimes).length === 0 && !!sessionStorage.getItem(startSlots)) {
      StartTimes = JSON.parse(sessionStorage.getItem(startSlots))[index];
    }

    if (Object.keys(EndTimes).length === 0 && !!sessionStorage.getItem(endSlots)) {
      EndTimes = JSON.parse(sessionStorage.getItem(endSlots))[index];
    }

    return { StartTimes, EndTimes };
  }

  componentWillUnmount() {
    clearData(startSlots)
    clearData(endSlots)
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      monthlyTimeSlots,
      timeZone,
      intl,
      isDateSelected,
      onDateSelected,
      selectedIndex,
      pop,
      customServices,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    let bookingStartDate =
      values.hasOwnProperty('booking') && !!values.booking[this.state.currentIndex]
        ? values.booking[this.state.currentIndex].bookingStartDate &&
          values.booking[this.state.currentIndex].bookingStartDate.date
          ? values.booking[this.state.currentIndex].bookingStartDate.date
          : null
        : null;
    let bookingStartTime =
      values.hasOwnProperty('booking') && !!values.booking[this.state.currentIndex]
        ? values.booking[this.state.currentIndex].bookingStartTime
          ? values.booking[this.state.currentIndex].bookingStartTime
          : null
        : null;
    let bookingEndDate =
      values.hasOwnProperty('booking') && !!values.booking[this.state.currentIndex]
        ? values.booking[this.state.currentIndex].bookingEndDate &&
          values.booking[this.state.currentIndex].bookingEndDate.date
          ? values.booking[this.state.currentIndex].bookingEndDate.date
          : null
        : null;

    let startTimeDisabled = !bookingStartDate;
    let endDateDisabled = !bookingStartDate || !bookingStartTime;
    let endTimeDisabled = !bookingStartDate || !bookingStartTime || !bookingEndDate;

    if (!startTimeDisabled && !isDateSelected) {
      onDateSelected();
      selectedIndex(this.state.currentIndex);
    }

    let timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );

    let timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      timeZone
    );

    let availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate
    );

    let firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    let { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate
    );

    let availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingEndDate || endDate,
      selectedTimeSlot
    );

    availableStartTimes = this.updatedSlots(
      availableStartTimes,
      availableEndTimes,
      this.state.currentIndex
    ).StartTimes;
    availableEndTimes = this.updatedSlots(
      availableStartTimes,
      availableEndTimes,
      this.state.currentIndex
    ).EndTimes;

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day =>
          !timeSlotsOnSelectedMonth.find(timeSlot =>
            isDayMomentInsideRange(
              day,
              timeSlot.attributes.start,
              timeSlot.attributes.end,
              timeZone
            )
          )
      : () => false;

    const placeholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, TODAY)
    );

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });

    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldDateAndTimeInput.css field
     */

    return (
      <FieldArray name="booking">
        {({ fields }) =>
          fields.map((name, index) => (
            <>
              <div className={classes} key={name}>
                <div className={css.formRow}>
                  <div className={classNames(css.field, css.startDate)}>
                    <FieldDateInput
                      className={css.fieldDateInput}
                      name={`${name}.bookingStartDate`}
                      id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
                      label={startDateInputProps.label}
                      placeholderText={startDateInputProps.placeholderText}
                      format={v =>
                        v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                      }
                      parse={v =>
                        v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                      }
                      isDayBlocked={isDayBlocked}
                      onChange={e => this.onBookingStartDateChange(e, index)}
                      onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                      onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                      navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                      navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                      useMobileMargins
                      showErrorMessage={false}
                      validate={bookingDateRequired('Required')}
                      onClose={event =>
                        this.setState({
                          currentMonth: getMonthStartInTimeZone(
                            event?.date ?? TODAY,
                            this.props.timeZone
                          ),
                        })
                      }
                    />
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={classNames(css.field, css.endDateHidden)}>
                    <FieldDateInput
                      {...endDateInputProps}
                      name={`${name}.bookingEndDate`}
                      id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
                      className={css.fieldDateInput}
                      label={endDateInputProps.label}
                      placeholderText={endDateInputProps.placeholderText}
                      format={v =>
                        v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                      }
                      parse={v =>
                        v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                      }
                      isDayBlocked={isDayBlocked}
                      onChange={e => this.onBookingEndDateChange(e, index)}
                      onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                      onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                      navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                      navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                      isOutsideRange={day =>
                        this.isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone)
                      }
                      useMobileMargins
                      showErrorMessage={false}
                      validate={bookingDateRequired('Required')}
                      disabled={endDateDisabled}
                      showLabelAsDisabled={endDateDisabled}
                    />
                  </div>

                  <div className={css.field}>
                    <FieldSelect
                      name={`${name}.bookingStartTime`}
                      id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
                      className={
                        name
                          .split('[')[1]
                          .split('')[0]
                          .toString() === this.state.currentIndex.toString()
                          ? css.fieldSelect
                          : css.fieldSelectDisabled
                      }
                      selectClassName={
                        name
                          .split('[')[1]
                          .split('')[0]
                          .toString() === this.state.currentIndex.toString()
                          ? css.select
                          : css.selectDisabled
                      }
                      label={startTimeLabel}
                      disabled={startTimeDisabled}
                      onChange={e => this.onBookingStartTimeChange(e, index)}
                    >
                      {name
                        .split('[')[1]
                        .split('')[0]
                        .toString() === this.state.currentIndex.toString() ? (
                        availableStartTimes.map(p => (
                          <option key={p.timeOfDay} value={p.timestamp}>
                            {p.timeOfDay}
                          </option>
                        ))
                      ) : values.hasOwnProperty('booking') && !!values.booking[index] ? (
                        this.getUpdateSlots(
                          intl,
                          monthlyTimeSlots,
                          timeZone,
                          Object.entries(this.state).find(([i, value], stateIndex) => {
                            if (!isNaN(+i)) {
                              if (
                                this.state[i]?.startDate?.toString() ===
                                values?.booking[index]?.bookingStartDate?.date?.toString()
                              ) {
                                return this.state[i]?.startDate;
                              }
                            }
                          })[1]?.startDate,
                          Object.entries(this.state).find(([i, value], stateIndex) => {
                            if (!isNaN(+i)) {
                              if (
                                this.state[i]?.startDate?.toString() ===
                                values?.booking[index]?.bookingStartDate?.date?.toString()
                              ) {
                                return this.state[i]?.endDate;
                              }
                            }
                          })[1]?.endDate,
                          getMonthlyTimeSlots,
                          getTimeSlots,
                          getAvailableStartTimes,
                          getAllTimeValues,
                          getAvailableEndTimes,
                          index
                        ).availableStartTimes.map(p => (
                          <option key={p.timeOfDay} value={p.timestamp}>
                            {p.timeOfDay}
                          </option>
                        ))
                      ) : (
                        <option>{placeholderTime}</option>
                      )}
                    </FieldSelect>
                  </div>

                  <div
                    className={
                      name
                        .split('[')[1]
                        .split('')[0]
                        .toString() === this.state.currentIndex.toString()
                        ? css.lineBetween
                        : css.lineBetweenDisabled
                    }
                  >
                    -
                  </div>

                  <div className={css.field}>
                    <FieldSelect
                      name={`${name}.bookingEndTime`}
                      id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
                      className={
                        name
                          .split('[')[1]
                          .split('')[0]
                          .toString() === this.state.currentIndex.toString()
                          ? css.fieldSelect
                          : css.fieldSelectDisabled
                      }
                      selectClassName={
                        name
                          .split('[')[1]
                          .split('')[0]
                          .toString() === this.state.currentIndex.toString()
                          ? css.select
                          : css.selectDisabled
                      }
                      label={endTimeLabel}
                      disabled={endTimeDisabled}
                      onChange={e => this.onBookingEndTimeChange(e, index)}
                    >
                      {name
                        .split('[')[1]
                        .split('')[0]
                        .toString() === this.state.currentIndex.toString() ? (
                        availableEndTimes.map(p => (
                          <option
                            key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                            value={p.timestamp}
                          >
                            {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                          </option>
                        ))
                      ) : values.hasOwnProperty('booking') && !!values.booking[index] ? (
                        this.getUpdateSlots(
                          intl,
                          monthlyTimeSlots,
                          timeZone,
                          Object.entries(this.state).find(([i, value], stateIndex) => {
                            if (!isNaN(+i)) {
                              if (
                                this.state[i]?.startDate?.toString() ===
                                values?.booking[index]?.bookingStartDate?.date?.toString()
                              ) {
                                return this.state[i]?.startDate;
                              }
                            }
                          })[1]?.startDate,
                          Object.entries(this.state).find(([i, value], stateIndex) => {
                            if (!isNaN(+i)) {
                              if (
                                this.state[i]?.startDate.toString() ===
                                values?.booking[index]?.bookingStartDate?.date?.toString()
                              ) {
                                return this.state[i]?.endDate;
                              }
                            }
                          })[1].endDate,
                          getMonthlyTimeSlots,
                          getTimeSlots,
                          getAvailableStartTimes,
                          getAllTimeValues,
                          getAvailableEndTimes,
                          index
                        ).availableEndTimes.map(p => (
                          <option
                            key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                            value={p.timestamp}
                          >
                            {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                          </option>
                        ))
                      ) : (
                        <option>{placeholderTime}</option>
                      )}
                      {/* {name
                      .split('[')[1]
                      .split('')[0]
                      .toString() === this.state.currentIndex.toString() &&
                    (bookingStartTime || startTime) ? (
                      availableEndTimes.map(p => (
                        <option
                          key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                          value={p.timestamp}
                        >
                          {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                        </option>
                      ))
                    ) : (
                      <option>{placeholderTime}</option>
                    )} */}
                    </FieldSelect>
                  </div>
                </div>
                {this.state.hasOwnProperty('shouldDisplay') &&
                this.state.shouldDisplay[index] &&
                customServices &&
                customServices.length > 0 ? (
                  <>
                    <Divider />
                    <label className={css.extraServicesTitle}>
                      <FormattedMessage id="BookingTimeForm.extraServicesTitle" />
                    </label>
                    <Select
                      placeholder="select additional service"
                      name={`${name}.extraServices`}
                      onChange={value => {
                        this.onAdditionalServiceChange(value, index);
                      }}
                      mode="multiple"
                    >
                      {customServices.map(service => {
                        const { nameInput, price, inputType, unitTypeService: unitType } = service;

                        if (price === 0 || inputType === 'required') {
                          return null;
                        }

                        return (
                          <option key={nameInput} value={nameInput}>
                            {nameInput}
                          </option>
                        );
                      })}
                    </Select>
                  </>
                ) : null}
              </div>
              <span
                style={{ display: index === 0 ? 'none' : 'block' }}
                onClick={() => {
                  fields.remove(index);
                  // this.setState({ currentIndex: index });
                  this.updateState(index);
                }}
              >
                <DeleteFilled />
              </span>
            </>
          ))
        }
      </FieldArray>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;

import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../';

import css from './SectionEarnUp.module.css';

const SectionEarnUp = props => {
  const { rootClassName, className, } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionEarnUp.title" />
          </h2>
          <NamedLink
            className={css.link}    
            name="SearchPage"
        >
            <FormattedMessage id="SectionEarnUp.button" />
        </NamedLink>
        </div>
    </div>
  );
};

SectionEarnUp.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionEarnUp.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionEarnUp;

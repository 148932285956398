// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RangeSlider_root__2cIvm {\n  position: relative;\n  margin: 0 10px;\n  width: calc(100% - 20px);\n  height: 24px;\n}\n\n.RangeSlider_activeHandle__1JUQM {\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://src/components/RangeSlider/RangeSlider.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".root {\n  position: relative;\n  margin: 0 10px;\n  width: calc(100% - 20px);\n  height: 24px;\n}\n\n.activeHandle {\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "RangeSlider_root__2cIvm",
	"activeHandle": "RangeSlider_activeHandle__1JUQM"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconEmailSuccess_root__R6SBE {\n}\n\n.IconEmailSuccess_marketplaceStroke__hPvcN {\n  stroke: var(--marketplaceColor);\n}\n\n.IconEmailSuccess_successFill__3NAul {\n  fill: var(--successColor);\n}\n\n.IconEmailSuccess_checkStroke__3hLmC {\n  stroke: var(--matterColorLight);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconEmailSuccess/IconEmailSuccess.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".root {\n}\n\n.marketplaceStroke {\n  stroke: var(--marketplaceColor);\n}\n\n.successFill {\n  fill: var(--successColor);\n}\n\n.checkStroke {\n  stroke: var(--matterColorLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconEmailSuccess_root__R6SBE",
	"marketplaceStroke": "IconEmailSuccess_marketplaceStroke__hPvcN",
	"successFill": "IconEmailSuccess_successFill__3NAul",
	"checkStroke": "IconEmailSuccess_checkStroke__3hLmC"
};
export default ___CSS_LOADER_EXPORT___;

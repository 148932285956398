const {Amplify, Storage} = require('aws-amplify') 
 
exports.configureAWS = () => { 
  Storage.configure({ 
    bucket: 'subler', 
    identityPoolId: "us-east-2:b957a3dc-2e74-467b-ab8a-f6b4da2c47cc", 
    region: 'us-east-2', 
 
  }); 
    Amplify.configure({ 
      Auth: { 
      identityPoolId: "us-east-2:b957a3dc-2e74-467b-ab8a-f6b4da2c47cc", 
      region: 'us-east-2', 
      bucket: 'subler', 
    }, 
    Storage: { 
      AWSS3: { 
        bucket: 'subler', 
        region: 'us-east-2' 
      } 
    } 
  }); 
}
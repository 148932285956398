import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingServicesForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { IconArrowHead } from '../../components';

import css from './EditListingServicesPanel.module.css';

const { Money } = sdkTypes;

const EditListingServicesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    currentUser,
    onNextTab,
    onPreviousTab,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const userName = currentUser ? currentUser.attributes.profile.firstName : '';

  const { publicData } = currentListing.attributes;
  var { customServices } = publicData;

  const initializeData = () => {
    customServices.map(service => {
      if(!(service.price instanceof Money)) {
        service.price = new Money(service.price, 'USD');
      }
    })

    return customServices;
  }

  var customServicesList = [
    {
      nameInput: "Cleaning Fee",
      inputType: "optional",
      price: new Money(0, 'USD'),
      unitType: "perHour",
    },
    {
      nameInput: "Lights",
      inputType: "optional",
      price: new Money(0, 'USD'),
      unitType: "perHour",
    },
    {
      nameInput: "Supervisor",
      inputType: "optional",
      price: new Money(0, 'USD'),
      unitType: "perHour",
    },
    {
      nameInput: "Security",
      inputType: "optional",
      price: new Money(0, 'USD'),
      unitType: "oneTime",
    },
    {
      nameInput: "AV Equipment",
      inputType: "optional",
      price: new Money(0, 'USD'),
      unitType: "perHour",
    }
  ]

  const form = (
    <EditListingServicesForm
      className={css.form}
      initialValues={{ customServices: customServices ? initializeData() : customServicesList }}
      onSubmit={values => {
        const { customServices } = values;
        var newCustomServices = [];

        for (var i = 0; i < customServices.length; i++) {
          newCustomServices.push({
            nameInput: customServices[i].nameInput,
            inputType: customServices[i].inputType,
            price: customServices[i].price.amount,
            unitType: customServices[i].unitType,
          });
        }

        const updateValues = {
          publicData: { 
            customServices: newCustomServices
          }
        };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      onManageDisableScrolling={onManageDisableScrolling}
      userName={userName}
    />
  );

  return (
    <div className={classes}>
      <div className={css.steps}>
      <button type='button' className={css.backButton} onClick={() => {
            onPreviousTab();
          }}>
            <IconArrowHead direction={"left"} isBig={true}/>
          </button>
        <div className={css.stepActive}>
          <FormattedMessage 
            id="EditListingServicesPanel.currentStep" 
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingServicesPanel.nextStep" />
        </div>
        <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
      </div>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingServicesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingServicesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingServicesPanel;

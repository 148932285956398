// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconCheckMark_root__yNjLY {\n  width: 24px;\n  height: 24px;\n  stroke: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconCheckmark/IconCheckMark.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,+BAA+B;AACjC","sourcesContent":[".root {\n  width: 24px;\n  height: 24px;\n  stroke: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconCheckMark_root__yNjLY"
};
export default ___CSS_LOADER_EXPORT___;

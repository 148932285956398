// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconKeysSuccess_strokeMarketplaceColor__3lGUG {\n  stroke: var(--marketplaceColor);\n}\n\n.IconKeysSuccess_fillSuccessColor__1j48C {\n  fill: var(--successColor);\n}\n\n.IconKeysSuccess_strokeLightColor__35sFg {\n  stroke: var(--matterColorLight);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconKeysSuccess/IconKeysSuccess.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".strokeMarketplaceColor {\n  stroke: var(--marketplaceColor);\n}\n\n.fillSuccessColor {\n  fill: var(--successColor);\n}\n\n.strokeLightColor {\n  stroke: var(--matterColorLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strokeMarketplaceColor": "IconKeysSuccess_strokeMarketplaceColor__3lGUG",
	"fillSuccessColor": "IconKeysSuccess_fillSuccessColor__1j48C",
	"strokeLightColor": "IconKeysSuccess_strokeLightColor__35sFg"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
    IconSocialMediaFacebook,
    IconSocialMediaInstagram,
    IconSocialMediaTwitter,
    ExternalLink
} from '../../components';

import css from './SectionAboutUs.module.css';

import forth from '../../assets/LandingPageImages/forth.webp';

const SectionAboutUs = props => {

    const renderSocialMediaLinks = intl => {
        const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
        const siteTwitterPage = twitterPageURL(siteTwitterHandle);

        const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
        const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
        const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

        const fbLink = siteFacebookPage ? (
            <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
                <IconSocialMediaFacebook className={css.smIcon} />
            </ExternalLink>
        ) : null;

        const twitterLink = siteTwitterPage ? (
            <ExternalLink
                key="linkToTwitter"
                href={siteTwitterPage}
                className={css.icon}
                title={goToTwitter}
            >
                <IconSocialMediaTwitter className={css.smIcon} />
            </ExternalLink>
        ) : null;

        const instragramLink = siteInstagramPage ? (
            <ExternalLink
                key="linkToInstagram"
                href={siteInstagramPage}
                className={css.icon}
                title={goToInsta}
            >
                <IconSocialMediaInstagram className={css.smIcon} />
            </ExternalLink>
        ) : null;
        return [fbLink, twitterLink, instragramLink].filter(v => v != null);
    };

    const { rootClassName, className, intl } = props;

    const socialMediaLinks = renderSocialMediaLinks(intl);

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.steps}>
                <div className={css.step}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionAboutUs.title" />
                    </h2>
                    <p className={css.stepText}>
                        <FormattedMessage id="SectionAboutUs.text1" />
                    </p>
                    <div className={css.someLinks}>{socialMediaLinks}</div>
                </div>

                <div className={css.step}>
                    <img src={forth} alt="Female Soccer Coach" className={css.image} />
                </div>
            </div>
        </div>
    );
};

SectionAboutUs.defaultProps = {
    rootClassName: null,
    className: null,
};

SectionAboutUs.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionAboutUs;

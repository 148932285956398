// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutWrapperTopbar_root__1lfP0 {\n  width: 100%;\n  position: sticky;\n  top: 0;\n  z-index: var(--zIndexTopbar);\n}\n", "",{"version":3,"sources":["webpack://src/components/LayoutWrapperTopbar/LayoutWrapperTopbar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,MAAM;EACN,4BAA4B;AAC9B","sourcesContent":[".root {\n  width: 100%;\n  position: sticky;\n  top: 0;\n  z-index: var(--zIndexTopbar);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LayoutWrapperTopbar_root__1lfP0"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_root__2DIg_ {\n  width: auto;\n  height: 100%;\n  position: relative;\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/Menu/Menu.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".root {\n  width: auto;\n  height: 100%;\n  position: relative;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Menu_root__2DIg_"
};
export default ___CSS_LOADER_EXPORT___;

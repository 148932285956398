import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionFeaturedListings.module.css';
import { NamedLink, ListingCard } from '../';

const SectionFeaturedListings = props => {
    const { rootClassName, className, listings } = props;

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.steps}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionFeaturedListings.title" />
                </h2>
                <p className={css.description}>
                    <FormattedMessage id="SectionFeaturedListings.description" />
                </p>
                <NamedLink
                    className={css.link}
                    name="SearchPage"
                >
                    <FormattedMessage id="SectionFeaturedListings.link" />
                </NamedLink>
            </div>
            <div className={css.listings}>
                {listings.map(l => {
                    return (
                        <div className={css.listing} key={l.id.uuid}>
                            <ListingCard listing={l} showAuthorInfo={false} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

SectionFeaturedListings.defaultProps = {
    rootClassName: null,
    className: null,
};

SectionFeaturedListings.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionFeaturedListings;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldCheckboxGroup_root__RFvjG {\n  margin: 0;\n  padding: 0;\n  border: none;\n}\n\n.FieldCheckboxGroup_list__PmD_Q {\n  margin: 0;\n}\n\n@media (min-width: 768px) {\n\n.FieldCheckboxGroup_twoColumns__HP1Wb {\n    columns: 2\n}\n  }\n\n.FieldCheckboxGroup_item__2HRmq {\n  padding: 2px 0;\n\n  /* Fix broken multi-column layout in Chrome */\n  page-break-inside: avoid\n}\n\n@media (min-width: 768px) {\n\n.FieldCheckboxGroup_item__2HRmq {\n    padding: 4px 0\n}\n  }\n", "",{"version":3,"sources":["webpack://src/components/FieldCheckboxGroup/FieldCheckboxGroup.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAGE;;AADF;IAEI;AAEJ;EADE;;AAGF;EACE,cAAc;;EAEd,6CAA6C;EAC7C;AAKF;;AAHE;;AANF;IAOI;AAEJ;EADE","sourcesContent":[".root {\n  margin: 0;\n  padding: 0;\n  border: none;\n}\n\n.list {\n  margin: 0;\n}\n\n.twoColumns {\n  @media (--viewportMedium) {\n    columns: 2;\n  }\n}\n\n.item {\n  padding: 2px 0;\n\n  /* Fix broken multi-column layout in Chrome */\n  page-break-inside: avoid;\n\n  @media (--viewportMedium) {\n    padding: 4px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FieldCheckboxGroup_root__RFvjG",
	"list": "FieldCheckboxGroup_list__PmD_Q",
	"twoColumns": "FieldCheckboxGroup_twoColumns__HP1Wb",
	"item": "FieldCheckboxGroup_item__2HRmq"
};
export default ___CSS_LOADER_EXPORT___;

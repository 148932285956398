import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionSoftware.module.css';
import phone from '../../assets/LandingPageImages/phone.webp';

const SectionSoftware = props => {
  const { rootClassName, className, } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
        <div className={css.title}>
        <FormattedMessage id="SectionSoftware.title" />
      </div>
      <div className={css.steps}>
        <div className={css.stepMobileMiddle}>
            <img src={phone} alt="Subler software" className={css.phone} />
        </div>
        <div className={css.step}>
          <div className={css.subSection}>
            <h3 className={css.stepTitle}>
                <FormattedMessage id="SectionSoftware.part1Title" />
            </h3>
            <ul>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part1Text1" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part1Text2" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part1Text3" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part1Text4" />
                </li>
            </ul>
          </div>
          <div className={css.subSection}>
            <h3 className={css.stepTitle}>
                <FormattedMessage id="SectionSoftware.part2Title" />
            </h3>
            <ul>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part2Text1" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part2Text2" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part2Text3" />
                </li>
                <li className={css.stepText}>
                    <FormattedMessage id="SectionSoftware.part2Text4" />
                </li>
            </ul>
          </div>
        </div>

        <div className={css.stepMiddle}>
            <img src={phone} alt="Subler software" className={css.phone} />
        </div>

        <div className={css.stepRight}>
            <div className={css.subSection}>
                <h3 className={css.stepTitle}>
                    <FormattedMessage id="SectionSoftware.part3Title" />
                </h3>
                <ul>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part3Text1" />
                    </li>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part3Text2" />
                    </li>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part3Text3" />
                    </li>
                </ul>
            </div>
            <div className={css.subSection}>
                <h3 className={css.stepTitle}>
                    <FormattedMessage id="SectionSoftware.part4Title" />
                </h3>
                <ul>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part4Text1" />
                    </li>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part4Text2" />
                    </li>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part4Text3" />
                    </li>
                </ul>
            </div>
            <div className={css.subSection}>
                <h3 className={css.stepTitle}>
                    <FormattedMessage id="SectionSoftware.part5Title" />
                </h3>
                <ul>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part5Text1" />
                    </li>
                    <li className={css.stepText}>
                        <FormattedMessage id="SectionSoftware.part5Text2" />
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

SectionSoftware.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionSoftware.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSoftware;

import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { CopyBlock, nord } from "react-code-blocks";
import { IconArrowHead, Button, IconCopy } from '..';
import {
  createSlug,
} from '../../util/urlHelpers';
import config from '../../config';

import css from './EditListingFinishedPanel.module.css';

const EditListingFinishedPanel = props => {
  const {
    className,
    rootClassName,
    onSubmit,
    onPreviousTab,
    submitButtonText,
    currentUser
  } = props;

  const { canonicalRootURL } = config;

  const classes = classNames(rootClassName || css.root, className);
  const { id } = currentUser;

  const link = canonicalRootURL + "/u/" + id.uuid

  return (
    <div className={classes}>
      <div className={css.steps}>
        <button type='button' className={css.backButton} onClick={() => {
          onPreviousTab();
        }}>
          <IconArrowHead direction={"left"} isBig={true} />
        </button>
        <div className={css.stepActive}>
          <FormattedMessage
            id="EditListingFinishedPanel.currentStep"
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
      </div>
      <div
        className={css.form}
      >
        <div className={css.formContent}>
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingFinishedPanel.greeting"
            values={{
              b: (crunk) => <b>{crunk}</b>
            }}
          />
        </p>
          <Button
            className={css.copyToClipboard}
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <IconCopy className={css.icon} />
            <FormattedMessage id="EditListingFinishedPanel.copyToClipboardButton" />
          </Button>

        </div>
        <div className={css.formActions}>
          <Button
            className={css.submitButton}
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

EditListingFinishedPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingFinishedPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFinishedPanel;

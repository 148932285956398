// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeRange_root__2rjUv {\n}\n\n.TimeRange_bookingInfo__3K7vo {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.TimeRange_dateSection__2RSY0 {\n  margin-right: 5px;\n}\n", "",{"version":3,"sources":["webpack://src/components/TimeRange/TimeRange.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n}\n\n.bookingInfo {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.dateSection {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TimeRange_root__2rjUv",
	"bookingInfo": "TimeRange_bookingInfo__3K7vo",
	"dateSection": "TimeRange_dateSection__2RSY0"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionHowToo.module.css';

import third from '../../assets/LandingPageImages/third.webp';

const SectionHowToo = props => {
  const { rootClassName, className, } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
          <img src={third} alt="Female Soccer Coach" className={css.image}/>
        </div>

        <div className={css.step}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionHowToo.title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowToo.text1" />
          </p>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowToo.text2" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowToo.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowToo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowToo;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Track_root__60R25 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.Track_track__1e8Um {\n  /* Position */\n  position: absolute;\n  top: 50%;\n  margin-top: -4px;\n  margin-left: -10px;\n\n  /* Layout */\n  width: 100%;\n  height: 8px;\n  padding: 0 10px;\n\n  box-sizing: content-box;\n  background-color: var(--matterColorNegative);\n  border-radius: 4px;\n  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);\n}\n\n.Track_range__20jna {\n  position: absolute;\n  top: 50%;\n  margin-top: -4px;\n  height: 8px;\n  background-color: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/RangeSlider/Track.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,kBAAkB;;EAElB,WAAW;EACX,WAAW;EACX,WAAW;EACX,eAAe;;EAEf,uBAAuB;EACvB,4CAA4C;EAC5C,kBAAkB;EAClB,gDAAgD;AAClD;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,WAAW;EACX,yCAAyC;AAC3C","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.track {\n  /* Position */\n  position: absolute;\n  top: 50%;\n  margin-top: -4px;\n  margin-left: -10px;\n\n  /* Layout */\n  width: 100%;\n  height: 8px;\n  padding: 0 10px;\n\n  box-sizing: content-box;\n  background-color: var(--matterColorNegative);\n  border-radius: 4px;\n  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);\n}\n\n.range {\n  position: absolute;\n  top: 50%;\n  margin-top: -4px;\n  height: 8px;\n  background-color: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Track_root__60R25",
	"track": "Track_track__1e8Um",
	"range": "Track_range__20jna"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldSelect_root__1fbX- {\n}\n\n.FieldSelect_select__JkxZe {\n  color: var(--matterColorAnti);\n  background-color: var(--matterColorInput);\n  padding: 15px 20px;\n\n  border-radius: 3px\n}\n\n.FieldSelect_select__JkxZe > option {\n    color: var(--matterColor);\n  }\n\n.FieldSelect_select__JkxZe > option:disabled {\n    color: var(--matterColorDark);\n  }\n\n.FieldSelect_select__JkxZe:disabled {\n    color: var(--matterColorDark);\n    cursor: default;\n  }\n\n.FieldSelect_selectSuccess__j18E0 {\n  color: var(--matterColor);\n}\n\n.FieldSelect_selectError__leG_7 {\n  border-bottom-color: var(--failColor);\n}\n\n.FieldSelect_label__3xfqB {\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://src/components/FieldSelect/FieldSelect.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,6BAA6B;EAC7B,yCAAyC;EACzC,kBAAkB;;EAElB;AAaF;;AAXE;IACE,yBAAyB;EAC3B;;AACA;IACE,6BAA6B;EAC/B;;AAEA;IACE,6BAA6B;IAC7B,eAAe;EACjB;;AAGF;EACE,yBAAyB;AAC3B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n}\n\n.select {\n  color: var(--matterColorAnti);\n  background-color: var(--matterColorInput);\n  padding: 15px 20px;\n\n  border-radius: 3px;\n\n  & > option {\n    color: var(--matterColor);\n  }\n  & > option:disabled {\n    color: var(--matterColorDark);\n  }\n\n  &:disabled {\n    color: var(--matterColorDark);\n    cursor: default;\n  }\n}\n\n.selectSuccess {\n  color: var(--matterColor);\n}\n\n.selectError {\n  border-bottom-color: var(--failColor);\n}\n\n.label {\n  margin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FieldSelect_root__1fbX-",
	"select": "FieldSelect_select__JkxZe",
	"selectSuccess": "FieldSelect_selectSuccess__j18E0",
	"selectError": "FieldSelect_selectError__leG_7",
	"label": "FieldSelect_label__3xfqB"
};
export default ___CSS_LOADER_EXPORT___;

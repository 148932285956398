import React, { useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import { FieldCurrencyInput, FieldSelect } from '../../components';
import {
  convertMoneyToNumber,
  unitDivisor,
  ensureDotSeparator,
  ensureSeparator,
  truncateToSubUnitPrecision,
} from '../../util/currency';

import css from './EditListingServicesForm.module.css';

const { Money } = sdkTypes;

const CustomServices = (props) => {

  const {
    intl,
    priceName,
    unitName,
    priceClassName,
    unitClassName,
    priceUnitTypeClassName,
    currencyConfig,
    unitPerHourLabel,
    unitOneTimeLabel,
    label,
    defaultValue,
    unitType,
    deleteOption,
    onEditService,
    service
  } = props;

  const [isInput, setIsInput] = useState(defaultValue ? false : true);
  const [onDelete, setOnDelete] = useState(false);


  let formattedValue = 0;

  const initialValueIsMoney = defaultValue instanceof Money;

  const initialValue = initialValueIsMoney ? convertMoneyToNumber(defaultValue) : defaultValue;

  const hasInitialValue = typeof initialValue === 'number' && !isNaN(initialValue);

  // We need to handle number format - some locales use dots and some commas as decimal separator
  // TODO Figure out if this could be digged from React-Intl directly somehow
  const testSubUnitFormat = intl.formatNumber('1.1', currencyConfig);
  const usesComma = testSubUnitFormat.indexOf(',') >= 0;

  try {
    // whatever is passed as a default value, will be converted to currency string
    // Unformatted value is digits + localized sub unit separator ("9,99")
    const unformattedValue = hasInitialValue
      ? truncateToSubUnitPrecision(
        ensureSeparator(initialValue.toString(), usesComma),
        unitDivisor(currencyConfig.currency),
        usesComma
      )
      : '';
    // Formatted value fully localized currency string ("$1,000.99")
    formattedValue = hasInitialValue
      ? intl.formatNumber(ensureDotSeparator(unformattedValue), currencyConfig)
      : '';
  } catch (e) {
    log.error(e, 'currency-input-init-failed', { currencyConfig, defaultValue, initialValue });
    throw e;
  }

  const rootClassname = classNames(css.root, {
    [css.hideRoot]: onDelete
  });

  return (
    <div className={rootClassname}>
      <label className={css.label}>
        {label}
        {deleteOption && isInput ? (
          <div>
            <button
              className={css.editButton}
              type='button'
              onClick={() => {
                const newPrice = document.getElementById(priceName).value;
                const newUnit = document.getElementById(unitName).value;

                const newService = {
                    name: label,
                    amount: parseFloat(newPrice.substring(1)) * 100,
                    unitType: newUnit,
                }
                
                onEditService(priceName, newService)
                setIsInput(false);
              }}
            >
              Save
            </button>
            <button
              className={css.deleteButton}
              type='button'
              onClick={() => {
                setIsInput(false)
              }}
            >
              Cancel
            </button>
          </div>
        ) : deleteOption ? (
          <div>
            <button
              className={css.editButton}
              type='button'
              onClick={() => {
                setIsInput(true);
              }}
            >
              Edit
            </button>
            <button
              className={css.deleteButton}
              type='button'
              onClick={() => {
                setOnDelete(true);
                props.onDeleteService(priceName);
              }}
            >
              Delete
            </button>
          </div>
        ) : null}
      </label>
      <div className={css.customPrices}>
        {isInput ? (
          <>
            <FieldCurrencyInput
              id={priceName}
              name={priceName}
              className={priceClassName}
              currencyConfig={currencyConfig}
            />
            <FieldSelect
              id={unitName}
              name={unitName}
              className={unitClassName}
              selectClassName={priceUnitTypeClassName}
            >
              <option value="perHour">{unitPerHourLabel}</option>
              <option value="oneTime">{unitOneTimeLabel}</option>
            </FieldSelect>
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={() => setIsInput(true)}
              className={css.inputButton}
            >
              {formattedValue}
            </button>
            <div className={css.unitSection}>
              <span className={css.unit}>{unitType}</span>
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default injectIntl(CustomServices);
// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page_root__3SIRI {\n  display: flex;\n  flex-direction: column;\n\n  min-height: 100vh;\n}\n\n.Page_content__2COkr {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n}\n\n.Page_scrollingDisabled__3F51e {\n  /* position: fixed and width are added to prevent iOS from scrolling content */\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/components/Page/Page.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;AACT;;AAEA;EACE,8EAA8E;EAC9E,eAAe;EACf,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n\n  min-height: 100vh;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.scrollingDisabled {\n  /* position: fixed and width are added to prevent iOS from scrolling content */\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Page_root__3SIRI",
	"content": "Page_content__2COkr",
	"scrollingDisabled": "Page_scrollingDisabled__3F51e"
};
export default ___CSS_LOADER_EXPORT___;

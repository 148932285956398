import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ProgressBar.module.css';

const ProgressBar = props => {
    const { className, rootClassName, progressBarClassName, progress } = props;
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const progressBarClasses = progressBarClassName || css.progressBar;

    return (
        <div className={classes}>
            <div className={progressBarClasses} style={{width: progress }}>
                <div className={css.progressBarFill} />
            </div>
        </div>
    );
}

ProgressBar.propTypes = {
    className: PropTypes.string,
    rootClassName: PropTypes.string,
    progressBarClassName: PropTypes.string,
    progressBarRootClassName: PropTypes.string,
}

export default ProgressBar;

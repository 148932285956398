// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutSingleColumn_root__1blLa {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.LayoutSingleColumn_layoutWrapperMain__zOvja {\n  /* Use all available space parent element (aka root) can give and don't shrink content */\n  flex-grow: 1;\n  flex-shrink: 0;\n\n  /* Expand to the full remaining width of the viewport */\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/LayoutSingleColumn/LayoutSingleColumn.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,wFAAwF;EACxF,YAAY;EACZ,cAAc;;EAEd,uDAAuD;EACvD,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.layoutWrapperMain {\n  /* Use all available space parent element (aka root) can give and don't shrink content */\n  flex-grow: 1;\n  flex-shrink: 0;\n\n  /* Expand to the full remaining width of the viewport */\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LayoutSingleColumn_root__1blLa",
	"layoutWrapperMain": "LayoutSingleColumn_layoutWrapperMain__zOvja"
};
export default ___CSS_LOADER_EXPORT___;

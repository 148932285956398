import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import { useLazyQuery } from '@apollo/client';
import { Get_Listings } from '../../Graphql/Queries';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [myListing, setMyListing] = useState([]);
  const [getListings, { loading: listingLoading, error, data: listingData }] = useLazyQuery(
    Get_Listings,
    {
      variables: {
        pageNumber: pagination?.page,
      },
      onCompleted(listingData) {
        for (const listing of listingData?.getListings) {
          for (const li of listings) {
            if (li.id.uuid === listing?.listingUuid) {
              li['showListing'] = listing.showListing;
              setMyListing(oldArr => {
                return [...oldArr, li];
              });
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    setMyListing([]);
    getListings();
    // console.log(listingData?.getListings.filter((listing,i)=>{
    //   if(listing.listingUuid === listings[i]?.id.uuid ){
    //     return listing
    //   }
    // }))
  }, [pagination?.page]);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {!listingLoading ? (
          myListing?.map((l, i) =>
            !l.showListing ? (
              <ListingCard
                className={css.listingCard}
                key={l.id.uuid + i}
                listing={l}
                renderSizes={cardRenderSizes}
                setActiveListing={setActiveListing}
                currentUser={currentUser}
              />
            ) : null
          )
        ) : (
          <h6>Please wait....</h6>
        )}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;

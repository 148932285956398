// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconEmailAttention_root__26B8c {\n}\n\n.IconEmailAttention_marketplaceStroke__3fC-O {\n  stroke: var(--marketplaceColor);\n}\n\n.IconEmailAttention_attentionStroke__9WKb7 {\n  stroke: var(--attentionColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconEmailAttention/IconEmailAttention.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".root {\n}\n\n.marketplaceStroke {\n  stroke: var(--marketplaceColor);\n}\n\n.attentionStroke {\n  stroke: var(--attentionColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconEmailAttention_root__26B8c",
	"marketplaceStroke": "IconEmailAttention_marketplaceStroke__3fC-O",
	"attentionStroke": "IconEmailAttention_attentionStroke__9WKb7"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { IconArrowHead } from '../../components';

import css from './EditListingFeaturesPanel.module.css';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onNextTab,
    onPreviousTab
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const userName = currentUser ? currentUser.attributes.profile.firstName : '';

  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const floorTypes = publicData && publicData.floorTypes;
  const amenities = publicData && publicData.amenities;

  const initialValues = { floorTypes, amenities };

  return (
    <div className={classes}>
      <div className={css.steps}>
      <button type='button' className={css.backButton} onClick={() => {
            onPreviousTab();
          }}>
            <IconArrowHead direction={"left"} isBig={true}/>
          </button>
        <div className={css.stepActive}>
          <FormattedMessage 
            id="EditListingFeaturesPanel.currentStep" 
            values={{
              b: (chunks) => <span className={css.focus}>{chunks}</span>
            }}
          />
        </div>
        <div className={css.step}>
          <FormattedMessage id="EditListingFeaturesPanel.nextStep" />
        </div>
        <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
      </div>
      <EditListingFeaturesForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={values => {
          const { floorTypes = [], amenities = [] } = values;

          const updatedValues = {
            publicData: { 
              floorTypes,
              amenities,
             },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        userName={userName}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'amenities',
    label: 'Amenities & Accessories',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'air-conditioning', label: 'Air Conditioning' },
        { key: 'scoreboard', label: 'Scoreboard' },
        { key: 'field-marking', label: 'Field Marking' },
        { key: 'speakers', label: 'Speakers' },
        { key: 'lights', label: 'Lights' },
        { key: 'practice-cage', label: 'Practice Cage' },
        { key: 'lockers', label: 'Lockers' },
        { key: 'restrooms', label: 'Restrooms' },
        { key: 'parking', label: 'Parking' },
        { key: 'tvs', label: 'TVs' },
        { key: 'ada-accessible', label: 'ADA Accessible' },
        { key: 'wifi', label: 'WiFi' },
        { key: 'balls', label: 'Balls' },
        { key: 'nets', label: 'Nets' },
        { key: 'markers', label: 'Markers' },
        { key: 'chairs', label: 'Chairs' },
        { key: 'tables', label: 'Tables' },
        { key: 'heating', label: 'Heating' },
        { key: 'wheelchair', label: 'Wheelchair' },
      ],
    },
  },
  {
    id: 'floorTypes',
    label: 'Floor Types',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_floorTypes'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'wood', label: 'Wood' },
        { key: 'concrete', label: 'Concrete' },
        { key: 'metal', label: 'Metal' },
        { key: 'plastic', label: 'Plastic' },
        { key: 'glass', label: 'Grass' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'spaceType',
    label: 'Space Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_spaceType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: 'auditorium', label: 'Auditorium' },
        { key: 'gym', label: 'Gym' },
        { key: 'baseball-field', label: 'Baseball Field' },
        { key: 'basketball-gymnasium', label: 'Basketball Gymnasium' },
        { key: 'cafeteria', label: 'Cafeteria' },
        { key: 'church', label: 'Church' },
        { key: 'classroom', label: 'Classroom' },
        { key: 'dance-studio', label: 'Dance Studio' },
        { key: 'football-field', label: 'Football Field' },
        { key: 'locker', label: 'Locker' },
        { key: 'soccer-field', label: 'Soccer Field' },
        { key: 'office', label: 'Office' },
        { key: 'parking-lot', label: 'Parking Lot' },
        { key: 'theatre', label: 'Theatre' },
        { key: 'wrestling', label: 'Wrestling' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
];

export const userAccountType = [
  {
    id: 'accountType',
    label: 'Account Type',
    config: {
      options: [
        //{ key: 'sport-organizer', label: 'Sport Organizer: Seeking for rent facilities for me and my team.', short_label: 'Sport Organizer' },
        { key: 'coach', label: 'Coach: Individual seeking to rent facilities for my team.', short_label: 'Coach', custom_pricing: true },
        { key: 'community-member', label: 'Community Member: Individual affiliated with a specific organization listed on Subler.', short_label: 'Community Member', custom_pricing: true},
        { key: 'facility-owner', label: 'Facility Owner: Organization seeking to rent my facilities on Subler.', short_label: 'Facility Owner', custom_pricing: false },
        { key: 'film-studio', label: 'Film Studio: Individual or entertainment organization seeking facilities for filming.', short_label: 'Film Studio', custom_pricing: true},
        { key: 'for-profit', label: 'Profit Organization: Organization that earns profits through its operations.', short_label: 'Profit Organizer', custom_pricing: true},
        { key: 'non-profit-organizer', label: 'Non-Profit Organization: 501(c)(3) organization that operates exclusively for religious, charitable, or recreational purposes.', short_label: 'Non-profit Organizer', custom_pricing: true },
        //{ key: 'athlete', label: 'Athlete: Seeking facilities for me.', short_label: 'Athlete' },
        //{ key: 'other', label: 'Other', short_label: 'Other' },
      ]
    }
  }
]

export const pricingOptions = {
  monthly: {
    label: 'Pro',
    price: '$99',
    pricingType: 'Billed Monthly',
    best: false,
    config: {
      options: [
        {key: 'unlimited-facility-listings', label: 'Unlimited Facility Listings'},
        {key: 'unlimited-leads-generated', label: 'Unlimited leads generated'},
        {key: 'premium-search-results-placement', label: 'Premium Search Results Placement'},
        {key: 'flexible-pricing-options', label: 'Flexible Pricing Options'},
        {key: 'online-calendar', label: 'Online Calendar'},
        {key: 'secure-payment-processing', label: 'Secure Payment Processing'},
        {key: 'renter-profile-verification', label: 'Renter Profile Verification'},
        {key: 'optimized-listings', label: 'Optimized Listings'},
      ]
    }
  },
  yearly: {
    label: 'Pro+',
    price: '$599',
    pricingType: 'Billed Yearly',
    best: true,
    config: {
      options: [
        {key: 'want-to-save-50', label: 'Want to save 50%?'},
        {key: 'homepage-features', label: 'Homepage Features'},
        {key: 'unlimited-facility-listings', label: 'Unlimited Facility Listings'},
        {key: 'unlimited-leads-generated', label: 'Unlimited leads generated'},
        {key: 'premium-search-results-placement', label: 'Premium Search Results Placement'},
        {key: 'flexible-pricing-options', label: 'Flexible Pricing Options'},
        {key: 'online-calendar', label: 'Online Calendar'},
        {key: 'secure-payment-processing', label: 'Secure Payment Processing'},
        {key: 'renter-profile-verification', label: 'Renter Profile Verification'},
        {key: 'optimized-listings', label: 'Optimized Listings'},
      ]
    }
  }
}

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const IMAGE_VARIANTS = {
    'fields.image': [
        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};

const firstId = process.env.REACT_APP_FEATURED_LISTING_ONE;
const secondId = process.env.REACT_APP_FEATURED_LISTING_TWO;
const thirdId = process.env.REACT_APP_FEATURED_LISTING_THREE;
const fourthId = process.env.REACT_APP_FEATURED_LISTING_FOUR;

// ================ Action types ================ //

export const FIRST_FEATURED_LISTING_REQUEST = 'app/LandingPage/FIRST_LISTING_REQUEST';
export const FIRST_FEATURED_LISTING_SUCCESS = 'app/LandingPage/FIRST_LISTING_SUCCESS';
export const FIRST_FEATURED_LISTING_ERROR = 'app/LandingPage/FIRST_LISTING_ERROR';

export const SECOND_FEATURED_LISTING_REQUEST = 'app/LandingPage/SECOND_LISTING_REQUEST';
export const SECOND_FEATURED_LISTING_SUCCESS = 'app/LandingPage/SECOND_LISTING_SUCCESS';
export const SECOND_FEATURED_LISTING_ERROR = 'app/LandingPage/SECOND_LISTING_ERROR';

export const THIRD_FEATURED_LISTING_REQUEST = 'app/LandingPage/THIRD_LISTING_REQUEST';
export const THIRD_FEATURED_LISTING_SUCCESS = 'app/LandingPage/THIRD_LISTING_SUCCESS';
export const THIRD_FEATURED_LISTING_ERROR = 'app/LandingPage/THIRD_LISTING_ERROR';

export const FOURTH_FEATURED_LISTING_REQUEST = 'app/LandingPage/FOURTH_LISTING_REQUEST';
export const FOURTH_FEATURED_LISTING_SUCCESS = 'app/LandingPage/FOURTH_LISTING_SUCCESS';
export const FOURTH_FEATURED_LISTING_ERROR = 'app/LandingPage/FOURTH_LISTING_ERROR';

export const NO_FEATURED_LISTING_REQUEST = 'app/LandingPage/NO_FEATURED_LISTING_REQUEST';
export const NO_FEATURED_LISTING_SUCCESS = 'app/LandingPage/NO_FEATURED_LISTING_SUCCESS';
export const NO_FEATURED_LISTING_ERROR = 'app/LandingPage/NO_FEATURED_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
    featuredListings: [],
    featuredListingsInProgress: false,
    featuredListingsError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FIRST_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case FIRST_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case FIRST_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case SECOND_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case SECOND_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case SECOND_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case THIRD_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case THIRD_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case THIRD_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case FOURTH_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case FOURTH_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case FOURTH_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case NO_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case NO_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: payload.response,
                featuredListingsInProgress: false,
            };
        case NO_FEATURED_LISTING_ERROR:
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        default:
            return state;
    }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const firstFeaturedListingRequest = () => ({
    type: FIRST_FEATURED_LISTING_REQUEST,
});

export const firstFeaturedListingSuccess = response => ({
    type: FIRST_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const firstFeaturedListingError = e => ({
    type: FIRST_FEATURED_LISTING_ERROR,
    payload: e,
});

export const secondFeaturedListingRequest = () => ({
    type: SECOND_FEATURED_LISTING_REQUEST,
});

export const secondFeaturedListingSuccess = response => ({
    type: SECOND_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const secondFeaturedListingError = e => ({
    type: SECOND_FEATURED_LISTING_ERROR,
    payload: e,
});

export const thirdFeaturedListingRequest = () => ({
    type: THIRD_FEATURED_LISTING_REQUEST,
});

export const thirdFeaturedListingSuccess = response => ({
    type: THIRD_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const thirdFeaturedListingError = e => ({
    type: THIRD_FEATURED_LISTING_ERROR,
    payload: e,
});

export const fourthFeaturedListingRequest = () => ({
    type: FOURTH_FEATURED_LISTING_REQUEST,
});

export const fourthFeaturedListingSuccess = response => ({
    type: FOURTH_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const fourthFeaturedListingError = e => ({
    type: FOURTH_FEATURED_LISTING_ERROR,
    payload: e,
});

export const noFeaturedListingRequest = () => ({
    type: NO_FEATURED_LISTING_REQUEST,
});

export const noFeaturedListingSuccess = response => ({
    type: NO_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const noFeaturedListingError = e => ({
    type: NO_FEATURED_LISTING_ERROR,
    payload: e,
});

export const firstFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(firstFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }).then(response => {
        const listingRef = {
            id: response.data.data.id,
            type: response.data.data.type
        };
        dispatch(addMarketplaceEntities(response));
        dispatch(firstFeaturedListingSuccess(listingRef));
        return response;
    })
        .catch(e => dispatch(firstFeaturedListingError(storableError(e))));
};

export const secondFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(secondFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }).then(response => {
        const listingRef = {
            id: response.data.data.id,
            type: response.data.data.type
        };
        dispatch(addMarketplaceEntities(response));
        dispatch(secondFeaturedListingSuccess(listingRef));
        return response;
    })
        .catch(e => dispatch(secondFeaturedListingError(storableError(e))));
};

export const thirdFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(thirdFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }).then(response => {
        const listingRef = {
            id: response.data.data.id,
            type: response.data.data.type
        };
        dispatch(addMarketplaceEntities(response));
        dispatch(thirdFeaturedListingSuccess(listingRef));
        return response;
    })
        .catch(e => dispatch(thirdFeaturedListingError(storableError(e))));
};

export const fourthFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(fourthFeaturedListingRequest());
    
    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
    }).then(response => {
        const listingRef = {
            id: response.data.data.id,
            type: response.data.data.type
        };
        dispatch(addMarketplaceEntities(response));
        dispatch(fourthFeaturedListingSuccess(listingRef));
        return response;
    })
        .catch(e => dispatch(fourthFeaturedListingError(storableError(e))));
}

export const queryUserListings = () => (dispatch, getState, sdk) => {
    dispatch(noFeaturedListingRequest());
    return sdk.listings
        .query({
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            perPage: 3,
        })
        .then(response => {
            // Pick only the id and type properties from the response listings
            const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
            dispatch(addMarketplaceEntities(response));
            dispatch(noFeaturedListingSuccess(listingRefs));
            return response;
        })
        .catch(e => dispatch(noFeaturedListingError(storableError(e))));
};

export const loadData = () => dispatch => {

    const listingsIds = [];

    if(firstId !== "") {
        listingsIds.push(firstId);
    }
    if(secondId !== "") {
        listingsIds.push(secondId);
    }
    if(thirdId !== "") {
        listingsIds.push(thirdId);
    }
    if(fourthId !== "") {
        listingsIds.push(fourthId);
    }

    if(listingsIds.length === 4) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
            dispatch(thirdFeaturedListing(listingsIds[2])), // get third featured listing
            dispatch(fourthFeaturedListing(listingsIds[3])), // get user listings
        ]);
    }
    if (listingsIds.length === 3) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
            dispatch(thirdFeaturedListing(listingsIds[2])), // get third featured listing
        ]);
    }
    if (listingsIds.length === 2) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
            dispatch(secondFeaturedListing(listingsIds[1])), // get second featured listing
        ]);
    }
    if (listingsIds.length === 1) {
        return Promise.all([
            dispatch(firstFeaturedListing(listingsIds[0])), // get first featured listing
        ]);
    }
    if (listingsIds.length === 0) {
        return Promise.all([
            dispatch(queryUserListings()),
        ]);
    }
};
import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { EditListingPhotosForm } from '../../forms';
import { IconArrowHead } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      currentUser,
      onPreviousTab,
      onNextTab,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);

    const userName = currentUser ? currentUser.attributes.profile.firstName : '';

    return (
      <div className={classes}>
        <div className={css.steps}>
          <button type='button' className={css.backButton} onClick={() => {
            onPreviousTab();
          }}>
            <IconArrowHead direction={"left"} isBig={true}/>
          </button>
          <div className={css.stepActive}>
            <FormattedMessage 
              id="EditListingPhotosPanel.currentStep" 
              values={{
                b: (chunks) => <span className={css.focus}>{chunks}</span>
              }}
            />
          </div>
          <div className={css.step}>
            <FormattedMessage id="EditListingPhotosPanel.nextStep" />
          </div>
          <button type='button' className={css.backButton} onClick={() => {
            onNextTab();
          }}>
            <IconArrowHead direction={"right"} isBig={true}/>
          </button>
        </div>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;
            onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          userName={userName}
          onPreviousTab={onPreviousTab}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;

import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { FieldCheckbox, NamedLink, Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { Divider } from 'antd';
export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDateSelected: false,
      selectedIndex: 0,
      disabled:false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.

  handleOnChange(formValues) {
    this.setState({
      disabled:true
    })
    setTimeout(() => {
      if (formValues.values.hasOwnProperty('booking')) {
        const value = formValues.values.booking;
        if (!!value[this.state.selectedIndex]?.bookingStartTime) {
          const { bookingStartTime, bookingEndTime, extraServices } = value[
            this.state.selectedIndex
          ];
          const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
          const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

          const listingId = this.props.listingId;
          const isOwnListing = this.props.isOwnListing;

          // We expect values bookingStartTime and bookingEndTime to be strings
          // which is the default case when the value has been selected through the form
          const isSameTime = bookingStartTime === bookingEndTime;

          if (
            bookingStartTime &&
            bookingEndTime &&
            !isSameTime &&
            !this.props.fetchLineItemsInProgress
          ) {
            this.props.onFetchTransactionLineItems({
              bookingData: {
                startDate: startDate || moment().format('YYYY-MM-DD'),
                endDate: endDate || moment().format('YYYY-MM-DD'),
                extraServices,
                currentUser: this.props.currentUser || {},
              },
              listingId,
              isOwnListing,
            });
          }
          setTimeout(() => {
            this.props.onFetchLineItems({ [this.state.selectedIndex]: this.props.lineItems });
            this.setState({
              disabled:false
            })
          },2000);
        }
      }
    }, 1000);
  }

  initialValues = {
    booking: [
      {
        bookingStartDate: { date: null },
        bookingEndDate: { date: null },
        bookingStartTime: null,
        bookingEndTime: null,
      },
    ],
  };

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const onChange = checked => {
      this.setState({ isChecked: checked });
    };

    return (
      <FinalForm
        {...rest}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={this.initialValues}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            lineItemsArr,
            fetchLineItemsArrayInProgress,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            customServices,
            hasCompleteProfile,
            currentUser,
            form: {
              mutators: { push, pop },
            },
          } = fieldRenderProps;
          let bookingStartLabel;
          let bookingEndLabel;
          const bookingData = [];
          if (values.hasOwnProperty('booking')) {
            for (const value of values.booking) {
              const startTime = value && value.bookingStartTime ? value.bookingStartTime : null;
              const endTime = value && value.bookingEndTime ? value.bookingEndTime : null;

              bookingStartLabel = intl.formatMessage({
                id: 'BookingTimeForm.bookingStartTitle',
              });
              bookingEndLabel = intl.formatMessage({
                id: 'BookingTimeForm.bookingEndTitle',
              });

              const startDate = startTime ? timestampToDate(startTime) : null;
              const endDate = endTime ? timestampToDate(endTime) : null;

              // This is the place to collect breakdown estimation data. See the
              // EstimatedBreakdownMaybe component to change the calculations
              // for customized payment processes.
              const bdata =
                startDate && endDate
                  ? {
                      unitType,
                      startDate,
                      endDate,
                      timeZone,
                    }
                  : {
                      unitType: 'null',
                      startDate: moment().format('YYYY-MM-DD'),
                      endDate: moment().format('YYYY-MM-DD'),
                      timeZone,
                    };

              bookingData.push(bdata);
            }
          }

          const showEstimatedBreakdown =
            bookingData.length > 0 &&
            lineItems &&
            Object.entries(lineItemsArr).length > 0 &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown
            ? bookingData.map((bookingd, i) => {
                return (
                  <div key={i} className={css.priceBreakdownContainer}>
                    <h3 className={css.priceBreakdownTitle}>
                      <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                    </h3>
                    <EstimatedBreakdownMaybe bookingData={bookingd} lineItems={lineItemsArr[i]} />
                  </div>
                );
              })
            : null;

          const loadingSpinnerMaybe = fetchLineItemsArrayInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  isDateSelected={this.state.isDateSelected}
                  onDateSelected={() => {
                    this.setState({ isDateSelected: true });
                  }}
                  selectedIndex={e => {
                    this.setState({ selectedIndex: e });
                  }}
                  pop={pop}
                  push={push}
                  customServices={customServices}
                />
              ) : null}
              <Divider />
              <div className="buttons" style={{ width: '50%', padding: '5%' }}>
                <PrimaryButton
                  disabled={!hasCompleteProfile || this.state.disabled}
                  onClick={() => {
                    push('booking', undefined);
                  }}
                >
                  <FormattedMessage id="BookingTimeForm.add" />
                </PrimaryButton>
              </div>
              <Divider />
              {fetchLineItemsArrayInProgress ?  <IconSpinner className={css.spinner} /> : bookingInfoMaybe}
              {bookingInfoErrorMaybe}
              <Divider />
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  disabled={!hasCompleteProfile || fetchLineItemsArrayInProgress ||this.state.disabled}
                >
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
              {!currentUser ? (
                <NamedLink className={css.warning} name="LoginPage">
                  <FormattedMessage id="BookingTimeForm.notLoggedIn" />
                </NamedLink>
              ) : hasCompleteProfile ? null : (
                <div className={css.warning}>
                  <FormattedMessage id="BookingTimeForm.hasCompleteProfile" />
                </div>
              )}
              <NamedLink name="TermsOfServicePage" className={css.smallPrint}>
                <FormattedMessage id="BookingTimeForm.termsAndConditions" />
              </NamedLink>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  lineItemsArr: {},
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  onFetchLineItems: func.isRequired,
  lineItems: array,
  lineItemsArr: object,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsArrayInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;

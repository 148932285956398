// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Handle_rootTouchBuffer__2-dpb {\n  /* Position */\n  position: absolute;\n  top: 50%;\n  margin-top: -22px;\n  margin-left: -22px;\n\n  /* Layout */\n  width: 44px;\n  height: 44px;\n  padding: 0;\n  border: 0;\n}\n\n.Handle_visibleHandle__1cQAv {\n  /* Position */\n  position: absolute;\n  top: 12px;\n  margin-left: 12px;\n\n  /* Layout */\n  width: 20px;\n  height: 20px;\n  border: 2px solid var(--marketplaceColor);\n  border-radius: 50%;\n  background-color: var(--matterColorLight);\n  cursor: pointer\n}\n\n.Handle_visibleHandle__1cQAv:hover {\n    transform: scale(1.1);\n    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);\n  }\n\n.Handle_dragged__201iF {\n  transform: scale(1.1);\n  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);\n}\n", "",{"version":3,"sources":["webpack://src/components/RangeSlider/Handle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,iBAAiB;EACjB,kBAAkB;;EAElB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,iBAAiB;;EAEjB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,kBAAkB;EAClB,yCAAyC;EACzC;AAMF;;AAJE;IACE,qBAAqB;IACrB,2CAA2C;EAC7C;;AAGF;EACE,qBAAqB;EACrB,2CAA2C;AAC7C","sourcesContent":[".rootTouchBuffer {\n  /* Position */\n  position: absolute;\n  top: 50%;\n  margin-top: -22px;\n  margin-left: -22px;\n\n  /* Layout */\n  width: 44px;\n  height: 44px;\n  padding: 0;\n  border: 0;\n}\n\n.visibleHandle {\n  /* Position */\n  position: absolute;\n  top: 12px;\n  margin-left: 12px;\n\n  /* Layout */\n  width: 20px;\n  height: 20px;\n  border: 2px solid var(--marketplaceColor);\n  border-radius: 50%;\n  background-color: var(--matterColorLight);\n  cursor: pointer;\n\n  &:hover  {\n    transform: scale(1.1);\n    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);\n  }\n}\n\n.dragged {\n  transform: scale(1.1);\n  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootTouchBuffer": "Handle_rootTouchBuffer__2-dpb",
	"visibleHandle": "Handle_visibleHandle__1cQAv",
	"dragged": "Handle_dragged__201iF"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionLogos.module.css';
import logo1 from '../../assets/LandingPageImages/logo1.webp';
import logo2 from '../../assets/LandingPageImages/logo2.webp';
import logo3 from '../../assets/LandingPageImages/logo3.webp';
import logo4 from '../../assets/LandingPageImages/logo4.webp';
import logo5 from '../../assets/LandingPageImages/logo5.webp';
import logo6 from '../../assets/LandingPageImages/logo6.webp';

const SectionLogos = props => {
  const { rootClassName, className, } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
            <img src={logo1} alt="Boys & Girls Clubs" className={css.logo} />
        </div>
        <div className={css.step}>
            <img src={logo2} alt="lausd logo" className={css.logo} />
        </div>
        <div className={css.step}>
            <img src={logo3} alt="Fitlife Productions" className={css.logo} />
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.step}>
            <img src={logo4} alt="FBC Logo" className={css.logoSquare} />
        </div>
        <div className={css.step}>
            <img src={logo5} alt="LA Catholics Logo" className={css.logo} />
        </div>
        <div className={css.step}>
            <img src={logo6} alt="Jubilee logo" className={css.logo} />
        </div>
      </div>
      <div className={css.howItWorksInfo}>
        <h2 className={css.title}><FormattedMessage id="SectionHowItWorks.howItWorksTitle" /></h2>
        <div className={css.listItem}>
          <h3 className={css.listTitle}>
            <FormattedMessage id="SectionHowItWorks.listTitle1" />
          </h3>
          <p className={css.listDescription}>
            <FormattedMessage id="SectionHowItWorks.listItem1" />
          </p>
        </div>
        <div className={css.listItem}>
          <h3 className={css.listTitle}>
            <FormattedMessage id="SectionHowItWorks.listTitle2" />
          </h3>
          <p className={css.listDescription}>
            <FormattedMessage id="SectionHowItWorks.listItem2" />
          </p>
        </div>
        <div className={css.listItem}>
          <h3 className={css.listTitle}>
            <FormattedMessage id="SectionHowItWorks.listTitle3" />
          </h3>
          <p className={css.listDescription}>
            <FormattedMessage id="SectionHowItWorks.listItem3" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionLogos.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionLogos.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLogos;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditListingPoliciesPanel_root__1ea3_ {\n  flex-grow: 1;\n  width: 100%;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 11px 24px 0 24px;\n}\n\n.EditListingPoliciesPanel_form__1Kjyt {\n  flex-grow: 1;\n}\n\n.EditListingPoliciesPanel_title__n8rVW {\n  margin-bottom: 19px\n}\n\n@media (min-width: 1024px) {\n\n.EditListingPoliciesPanel_title__n8rVW {\n    margin-bottom: 38px;\n    padding: 1px 0 7px 0\n}\n  }\n", "",{"version":3,"sources":["webpack://src/components/EditListingPoliciesPanel/EditListingPoliciesPanel.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;AAMF;;AAJE;;AAHF;IAII,mBAAmB;IACnB;AAEJ;EADE","sourcesContent":[".root {\n  flex-grow: 1;\n  width: 100%;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 11px 24px 0 24px;\n}\n\n.form {\n  flex-grow: 1;\n}\n\n.title {\n  margin-bottom: 19px;\n\n  @media (--viewportLarge) {\n    margin-bottom: 38px;\n    padding: 1px 0 7px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EditListingPoliciesPanel_root__1ea3_",
	"form": "EditListingPoliciesPanel_form__1Kjyt",
	"title": "EditListingPoliciesPanel_title__n8rVW"
};
export default ___CSS_LOADER_EXPORT___;

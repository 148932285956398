// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutsideClickHandler_root__1ovUd {\n  display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://src/components/OutsideClickHandler/OutsideClickHandler.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":[".root {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutsideClickHandler_root__1ovUd"
};
export default ___CSS_LOADER_EXPORT___;

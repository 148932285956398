// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BookingDateRangeLengthFilter_popupSize__37Wf3 {\n  padding: 0 10px 7px 10px;\n}\n\n.BookingDateRangeLengthFilter_duration__1oY7m {\n  padding: 0 24px 24px 24px;\n}\n", "",{"version":3,"sources":["webpack://src/components/BookingDateRangeLengthFilter/BookingDateRangeLengthFilter.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".popupSize {\n  padding: 0 10px 7px 10px;\n}\n\n.duration {\n  padding: 0 24px 24px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupSize": "BookingDateRangeLengthFilter_popupSize__37Wf3",
	"duration": "BookingDateRangeLengthFilter_duration__1oY7m"
};
export default ___CSS_LOADER_EXPORT___;

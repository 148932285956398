// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingLink_longWord__AO8tP {\n  /* fallback option */\n  word-break: break-all;\n  /* use break-word if available */\n  word-break: break-word;\n  hyphens: auto;\n}\n", "",{"version":3,"sources":["webpack://src/components/ListingLink/ListingLink.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,gCAAgC;EAChC,sBAAsB;EACtB,aAAa;AACf","sourcesContent":[".longWord {\n  /* fallback option */\n  word-break: break-all;\n  /* use break-word if available */\n  word-break: break-word;\n  hyphens: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"longWord": "ListingLink_longWord__AO8tP"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LimitedAccessBanner_root__Tva8u {\n  background-color: #df492a;\n  text-align: center;\n  padding: 10px 20px 9px;\n}\n\n.LimitedAccessBanner_text__3_aam {\n  margin: 0;\n  display: inline-block;\n  color: #fff;\n  font-size: 16px;\n  margin-bottom: 16px;\n  line-height: 20px;\n}\n\n.LimitedAccessBanner_button__2yYEa {\n  background: #2a3d4b;\n  margin: 0 16px;\n  padding: 8px 16px;\n  border-radius: 4px;\n  font-size: 14px;\n  color: #fff;\n  border: 0\n}\n\n.LimitedAccessBanner_button__2yYEa:hover {\n    text-decoration: none;\n    background: #364f61;\n  }\n", "",{"version":3,"sources":["webpack://src/components/LimitedAccessBanner/LimitedAccessBanner.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX;AAMF;;AAJE;IACE,qBAAqB;IACrB,mBAAmB;EACrB","sourcesContent":[".root {\n  background-color: #df492a;\n  text-align: center;\n  padding: 10px 20px 9px;\n}\n\n.text {\n  margin: 0;\n  display: inline-block;\n  color: #fff;\n  font-size: 16px;\n  margin-bottom: 16px;\n  line-height: 20px;\n}\n\n.button {\n  background: #2a3d4b;\n  margin: 0 16px;\n  padding: 8px 16px;\n  border-radius: 4px;\n  font-size: 14px;\n  color: #fff;\n  border: 0;\n\n  &:hover {\n    text-decoration: none;\n    background: #364f61;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LimitedAccessBanner_root__Tva8u",
	"text": "LimitedAccessBanner_text__3_aam",
	"button": "LimitedAccessBanner_button__2yYEa"
};
export default ___CSS_LOADER_EXPORT___;

import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { sendCoAccountInvitation } from '../../util/api';

const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');
const clientId = process.env.REACT_APP_INTEGRATION_ID;
const clientSecret = process.env.REACT_APP_INTEGRATION_SECRET;

const integrationSdk = flexIntegrationSdk.createInstance({
  clientId,
  clientSecret
});

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/CoAccountDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/CoAccountDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/CoAccountDetailsPage/SAVE_EMAIL_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/CoAccountDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        contactDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a request to invite a user to join as a co-account user.
 */
const requestSaveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentUser } = params;

  return integrationSdk.users.show({ email: email }).then(response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
    }
    const coAccountUser = entities[0];
    // GUEST INFORMATION
    const coAccountInvitation = coAccountUser.attributes.profile.metadata.coAccountInvitation || [];

    return integrationSdk.users
      .updateProfile(
        { id: coAccountUser.id, metadata: { coAccountInvitation: [...coAccountInvitation, { id: currentUser.id.uuid, name: currentUser.attributes.profile.displayName, state: 'pending', email: currentUser.attributes.email }] } }
      )
      .then(response => {
        sendCoAccountInvitation({ email: email, currentUser: currentUser }).then(res => console.log(res)).catch(e => { console.error(e) });
        // HOST INFORMATION
        const coAccountGuestInvitation = currentUser.attributes.profile.metadata.coAccountGuestInvitation || [];
        return integrationSdk.users.updateProfile({
          id: currentUser.id.uuid,
          metadata: { coAccountGuestInvitation: [...coAccountGuestInvitation, { id: coAccountUser.id.uuid, name: coAccountUser.attributes.profile.displayName, state: 'pending', email: email }] },
        }, {
          expand: true,
          include: ["profileImage"]
        }).then(response => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }

          const currentUser = entities[0];
          return currentUser;
        });
      })
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        console.error(e)
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      });
  })
    .catch(e => {
      console.error(e)
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save email and update the current user.
 */
const saveEmail = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
      })
      // error action dispatched in requestSaveEmail
      .catch(e => null)
  );
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { email, currentUser } = params;

  return dispatch(saveEmail({ email, currentUser }));
};

/**
 * Accept the invitation to join as a co-account user.
 */
export const acceptCoAccountInvitation = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { currentUser, coAccount } = params;

  return integrationSdk.users.show({ email: coAccount.email }).then(response => {
    // HOST INFORMATION
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
    }
    const coAccountUser = entities[0];
    // GUEST INFORMATION
    const coAccountGuest = coAccountUser.attributes.profile.metadata.coAccountGuest || [];
    var coAccountGuestInvitation = coAccountUser.attributes.profile.metadata.coAccountGuestInvitation || [];

    // delete the invitation from the coAccountInvitation array
    coAccountGuestInvitation = coAccountGuestInvitation.filter(invitation => invitation.id !== currentUser.id.uuid);

    return integrationSdk.users.updateProfile({
      id: coAccount.id,
      metadata: { coAccountGuest: [...coAccountGuest, { id: currentUser.id.uuid, name: currentUser.attributes.profile.displayName, state: 'accepted', email: currentUser.attributes.email }], coAccountGuestInvitation: [...coAccountGuestInvitation] }
    })
      .then(response => {
        const coAccountUsersAccepted = currentUser.attributes.profile.metadata.coAccount || [];
        var coAccountInvitation = currentUser.attributes.profile.metadata.coAccountInvitation || [];
        // delete the invitation from the coAccountGuestInvitation array
        coAccountInvitation = coAccountInvitation.filter(invitation => invitation.id !== coAccount.id);

        return integrationSdk.users.updateProfile({
          id: currentUser.id.uuid,
          metadata: { coAccountInvitation: [...coAccountInvitation], coAccount: [...coAccountUsersAccepted, { id: coAccount.id, name: coAccount.name, email: coAccount.email, state: 'accepted' }] }
        }, {
          expand: true,
        })
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }

            const currentUser = entities[0];
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(saveContactDetailsSuccess());
            return currentUser;
          })
          .catch(e => {
            dispatch(saveEmailError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
          });
      })
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      });
  })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    })
}

/**
 * Decline the invitation to join as a co-account user.
 */
export const declineCoAccountInvitation = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { currentUser, coAccount } = params;

  return integrationSdk.users.show({ email: coAccount.email }).then(response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
    }
    const coAccountUser = entities[0];

    // HOST INFORMATION
    var coAccountGuestInvitation = coAccountUser.attributes.profile.metadata.coAccountGuestInvitation || [];
    // delete the invitation from the coAccountGuestInvitation array
    coAccountGuestInvitation = coAccountGuestInvitation.filter(invitation => invitation.id !== currentUser.id.uuid);

    return integrationSdk.users.updateProfile({
      id: coAccount.id,
      metadata: { coAccountGuestInvitation: [...coAccountGuestInvitation] },
    })
      .then(response => {
        // GUEST INFORMATION
        var coAccountInvitation = currentUser.attributes.profile.metadata.coAccountInvitation || [];
        // delete the invitation from the coAccountInvitation array
        coAccountInvitation = coAccountInvitation.filter(invitation => invitation.id !== coAccount.id);

        return integrationSdk.users.updateProfile({
          id: currentUser.id.uuid,
          metadata: { coAccountInvitation: [...coAccountInvitation] },
        }, {
          expand: true,
        })
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }

            const currentUser = entities[0];
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(saveContactDetailsSuccess());
            return currentUser;
          })
          .catch(e => {
            dispatch(saveEmailError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
          });
      })
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      });
  })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    })
}

/**
 * Remove the co-account user from the co-account as guest.
 */
export const removeCoAccountUser = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { currentUser, coAccount } = params;

  return integrationSdk.users.show({ email: coAccount.email }).then(response => {
    // HOST INFORMATION
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
    }
    const coAccountUser = entities[0];
    var coAccountGuest = coAccountUser.attributes.profile.metadata.coAccountGuest || [];
    // delete the invitation from the coAccountGuest array
    coAccountGuest = coAccountGuest.filter(guest => guest.id !== currentUser.id.uuid);

    return integrationSdk.users.updateProfile({
      id: coAccount.id,
      metadata: { coAccountGuest: [...coAccountGuest] }
    })
      .then(response => {
        // GUEST INFORMATION
        var coAccountUsersAccepted = currentUser.attributes.profile.metadata.coAccount || [];
        // delete the invitation from the coAccountGuest array
        coAccountUsersAccepted = coAccountUsersAccepted.filter(guest => guest.id !== coAccount.id);

        return integrationSdk.users.updateProfile({
          id: currentUser.id.uuid,
          metadata: { coAccount: [...coAccountUsersAccepted] },
        }, {
          expand: true,
        })
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
  
            const currentUser = entities[0];
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(saveContactDetailsSuccess());
            return currentUser;
          })
          .catch(e => {
            dispatch(saveEmailError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
          });
      })
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      });
  })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    })
}

/**
 * Remove the co-account user from the co-account as host.
 */
export const removeCoAccount = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { currentUser, coAccount } = params;

  return integrationSdk.users.show({ email: coAccount.email }).then(response => {
    // GUEST INFORMATION
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
    }
    const coAccountUser = entities[0];
    var coAccountUsersAccepted = coAccountUser.attributes.profile.metadata.coAccount || [];
    // delete the invitation from the coAccountGuest array
    coAccountUsersAccepted = coAccountUsersAccepted.filter(guest => guest.id !== currentUser.id.uuid);

    return integrationSdk.users.updateProfile({
      id: coAccount.id,
      metadata: { coAccount: [...coAccountUsersAccepted] }
    })
      .then(response => {
        // HOST INFORMATION
        var coAccountGuest = currentUser.attributes.profile.metadata.coAccountGuest || [];
        // delete the invitation from the coAccountGuest array
        coAccountGuest = coAccountGuest.filter(guest => guest.id !== coAccount.id);

        return integrationSdk.users.updateProfile({
          id: currentUser.id.uuid,
          metadata: { coAccountGuest: [...coAccountGuest] },
        }, {
          expand: true,
        })
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
  
            const currentUser = entities[0];
            delete currentUser.id;
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(saveContactDetailsSuccess());
            return currentUser;
          })
          .catch(e => {
            dispatch(saveEmailError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
          });
      })
      .catch(e => {
        dispatch(saveEmailError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      });
  })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    })
}

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, NamedLink } from '../../components';
import { requiredFieldArrayCheckbox } from '../../util/validators';

import arrowIcon from './icons/arrow.png';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        invalid,
        intl,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        userName,
        values
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const greetingMessage = (
        <p className={css.greetingLabel}>
          <FormattedMessage
            id="EditListingFeaturesForm.greeting"
            values={{
              name: (<strong> {userName}</strong>),
              b: (crunk) => <b>{crunk}</b>
            }}
          />
        </p>
      );
      
      const floorTypesRequired = intl.formatMessage({
        id: 'EditListingFeaturesForm.floorTypesRequired',
      });

      const floorTypesOptions = findOptionsForSelectFilter('floorTypes', filterConfig);
      const amenitiesOptions = findOptionsForSelectFilter('amenities', filterConfig);

      const selectedFloorTypes = values.floorTypes ? floorTypesOptions.filter(option => values.floorTypes.includes(option.key)) : [];
      const selectedAmenities = values.amenities ? amenitiesOptions.filter(option => values.amenities.includes(option.key)) : [];

      const [dropdown, setDropdown] = useState("none");

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formContent}>
            {errorMessage}
            {errorMessageShowListing}
            {greetingMessage}

            <button
              type="button"
              className={
                dropdown === "none" || dropdown === "floorTypes" ? css.optionButton : css.optionHidden
              }
              onClick={() => {
                if(dropdown === "floorTypes") {
                  setDropdown("none");
                }
                else {
                  setDropdown("floorTypes");
                }
              }}
            >
              <div className={css.selectorHeader}>
              <FormattedMessage id="EditListingFeaturesForm.floorTypesLabel" />
              <img src={arrowIcon} alt="arrow" className={dropdown === "floorTypes" ? css.arrowIconUp : css.arrowIcon} />
              </div>
              <div className={css.selectedOptions}>
                {selectedFloorTypes.map((option, index) => (
                  <div key={option.value} className={css.selectedOption}>
                    {option.label}
                    {index !== selectedFloorTypes.length - 1 && <span className={css.separator}>,</span>}
                  </div>
                ))}
              </div>
            </button>

            <button
              type="button"
              className={
                dropdown === "none" || dropdown === "amenities" ? css.optionButton : css.optionHidden
              }
              onClick={() => {
                if(dropdown === "amenities") {
                  setDropdown("none");
                }
                else {
                  setDropdown("amenities");
                }
              }}
            >
              <div className={css.selectorHeader}>
                <FormattedMessage id="EditListingFeaturesForm.amenitiesLabel" />
                <img src={arrowIcon} alt="arrow" className={dropdown === "amenities" ? css.arrowIconUp : css.arrowIcon}/>
              </div>
              <div className={css.selectedOptions}>
                {selectedAmenities.map((option, index) => (
                  <div key={option.value} className={css.selectedOption}>
                    {option.label}
                    {index !== selectedAmenities.length - 1 && <span className={css.separator}>,</span>}
                  </div>
                ))}
              </div>
            </button>

            <div className={dropdown === "none" ? css.optionHidden : css.optionsPanel}>
              <FieldCheckboxGroup
                className={dropdown === "floorTypes" ? css.features : css.optionHidden}
                id="floorTypes"
                name="floorTypes"
                options={floorTypesOptions}
                twoColumns={true}
                validate={requiredFieldArrayCheckbox(floorTypesRequired)}
              />

              <FieldCheckboxGroup
                className={dropdown === "amenities" ? css.features : css.optionHidden}
                id="amenities"
                name="amenities"
                options={amenitiesOptions}
                twoColumns={true}
              />
            </div>

          </div>

          <div className={css.formActions}>
            <NamedLink name="LandingPage" className={css.closeLink}>
              <FormattedMessage id="EditListingWizard.closeAndSaveButton" />
            </NamedLink>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default  compose(injectIntl)(EditListingFeaturesForm);

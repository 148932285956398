// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageFromFile_root__85_n- {\n  display: block;\n  position: relative;\n  width: 100%;\n  margin: 0;\n  overflow: hidden;\n  background-color: var(--matterColorNegative); /* Loading BG color */\n}\n\n.ImageFromFile_threeToTwoWrapper__3LzUr {\n  position: relative;\n}\n\n.ImageFromFile_aspectWrapper__3nHEH {\n  padding-bottom: calc(100% * (3 / 3));\n}\n\n.ImageFromFile_rootForImage__262gm {\n  /* Layout - image will take space defined by aspect ratio wrapper */\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: var(--borderRadius);\n}\n", "",{"version":3,"sources":["webpack://src/components/ImageFromFile/ImageFromFile.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,4CAA4C,EAAE,qBAAqB;AACrE;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mEAAmE;EACnE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kCAAkC;AACpC","sourcesContent":[".root {\n  display: block;\n  position: relative;\n  width: 100%;\n  margin: 0;\n  overflow: hidden;\n  background-color: var(--matterColorNegative); /* Loading BG color */\n}\n\n.threeToTwoWrapper {\n  position: relative;\n}\n\n.aspectWrapper {\n  padding-bottom: calc(100% * (3 / 3));\n}\n\n.rootForImage {\n  /* Layout - image will take space defined by aspect ratio wrapper */\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: var(--borderRadius);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ImageFromFile_root__85_n-",
	"threeToTwoWrapper": "ImageFromFile_threeToTwoWrapper__3LzUr",
	"aspectWrapper": "ImageFromFile_aspectWrapper__3nHEH",
	"rootForImage": "ImageFromFile_rootForImage__262gm"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className, } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title1" />
          </h3>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title2" />
          </h3>
        </div>

        <div className={css.step}>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title1" />
          </h3>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title2" />
          </h3>
        </div>

        <div className={css.step}>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title3" />
          </h3>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title3" />
          </h3>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconSocialMediaTwitter_root__3kH2Y {\n  fill: var(--matterColor);\n  transition: var(--transitionStyleButton)\n}\n.IconSocialMediaTwitter_root__3kH2Y:hover {\n    fill: var(--marketplaceColor);\n  }\n", "",{"version":3,"sources":["webpack://src/components/IconSocialMediaTwitter/IconSocialMediaTwitter.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB;AAIF;AAHE;IACE,6BAA6B;EAC/B","sourcesContent":[".root {\n  fill: var(--matterColor);\n  transition: var(--transitionStyleButton);\n  &:hover {\n    fill: var(--marketplaceColor);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconSocialMediaTwitter_root__3kH2Y"
};
export default ___CSS_LOADER_EXPORT___;

import { bool, object, string } from 'prop-types';
import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './OwnListingLink.module.css';


const OwnListingLink = props => {
  const { className, children } = props;

  return (
    <NamedLink
      name="ManageListingsPage"
      className={className ? className : css.yourListingsLink}
    >
      <span className={css.menuItemBorder} />
      {children ? children : <FormattedMessage id="OwnListingLink.yourListings" />}
    </NamedLink>
  );
};

OwnListingLink.defaultProps = {
  className: null,
  listing: null,
  listingFetched: false,
  children: null,
};

OwnListingLink.propTypes = {
  className: string,
  listing: propTypes.ownListing,
  listingFetched: bool,
  children: object,
};

export default OwnListingLink;

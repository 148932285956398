// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutWrapperMain_root__3k0MQ {\n  /* Expand to the full remaining width of the viewport */\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/LayoutWrapperMain/LayoutWrapperMain.module.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":[".root {\n  /* Expand to the full remaining width of the viewport */\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LayoutWrapperMain_root__3k0MQ"
};
export default ___CSS_LOADER_EXPORT___;

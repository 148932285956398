// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconEdit_root__1v1in,\n.IconEdit_pencil__2cpkA {\n  stroke: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconEdit/IconEdit.module.css"],"names":[],"mappings":"AAAA;;EAEE,+BAA+B;AACjC","sourcesContent":[".root,\n.pencil {\n  stroke: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconEdit_root__1v1in",
	"pencil": "IconEdit_pencil__2cpkA"
};
export default ___CSS_LOADER_EXPORT___;

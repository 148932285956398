import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook className={css.smIcon} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.smIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.smIcon} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, currentUser } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const isAbleToPost = currentUser && currentUser.attributes?.profile?.metadata?.ableToPost;

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.infoLinks}>
              <div className={css.pagesLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <a href="https://getsubler.com" className={css.link}>
                      <FormattedMessage id="Footer.landingPage" />
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="HowItWorksPage" className={css.link}>
                      <FormattedMessage id="Footer.toHIWPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="SearchPage" className={css.link}>
                      <FormattedMessage id="Footer.bookASpace" />
                    </NamedLink>
                  </li>
                  {isAbleToPost && (
                    <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li>
                  )}
                </ul>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
              <div className={css.copyright}>
                <div className={css.copyrightText}>
                  <FormattedMessage id="Footer.copyright" />
                </div>
                <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
                <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

export default compose(
  connect(
    mapStateToProps,
  )
)(injectIntl(Footer));

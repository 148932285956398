import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  InlineTextButton, Logo,
  Menu, MenuContent,
  MenuItem, MenuLabel, NamedLink, ExternalLink,
  IconArrowHead
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './TopbarDesktop.module.css';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
});

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isAbleToPost = currentUser && currentUser.attributes?.profile?.metadata?.ableToPost;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  async function getCurrentUser() {
    const res = await sdk.currentUser.show();
    return res.data.data;
  }
  useEffect(() => {
    getCurrentUser().then((currentUser) => {
      setUser(currentUser)
    }).catch((error) => {
      console.error(error);
    });
  }, []);


  var messagesArray = user?.attributes?.profile?.privateData?.test;
  var messagesCount = messagesArray ? messagesArray.length : 0;

  const notificationDot = (notificationCount || messagesCount) > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  // const Booking = !authenticatedOnClientSide ? (
  //   <NamedLink
  //     className={css.inboxLink}
  //     name="Bookings"
  //   >
  //     <span className={css.inbox}>
  //       <FormattedMessage id="TopbarDesktop.booking" />
  //       {notificationDot}
  //     </span>
  //   </NamedLink>
  // ) : null;

  const findASpaceLink = (
    <NamedLink
      className={css.newLinks}
      name="MissingListing"
    >
      <span className={css.linkDefault}>
        <FormattedMessage id="TopbarDesktop.findASpace" />
      </span>
    </NamedLink>
  );

  const viewAllListingsLink = (
    <NamedLink
      className={css.newLinks}
      name="SearchPage"
    >
      <span className={css.linkDefault}>
        <FormattedMessage id="TopbarDesktop.viewAllListings" />
      </span>
    </NamedLink>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            name="ManageListingsPage"
            className={css.yourListingsLink}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListings" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const pagesMenu = (
    <Menu className={css.menuRoot}>
      <MenuLabel className={css.pagesMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span className={css.pagesMenuSpan}><FormattedMessage id="TopbarDesktop.pages" /></span>
        <IconArrowHead direction="down"/>
      </MenuLabel>
      <MenuContent className={css.pagesMenuContent} style={{left: true}} arrowPosition={0}>
        <MenuItem key="HowItWorksPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('HowItWorksPage'))}
            name="HowItWorksPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.howItWorksPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AboutPage">
          <NamedLink
            className={classNames(css.aboutPageLink, currentPageClass('AboutPage'))}
            name="AboutPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingLink =
    !isAuthenticatedOrJustHydrated || !isAbleToPost ? null : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );

  return (
    <nav className={classes}>
      <a href="https://getsubler.com" className={css.logoLink}>
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </a>
      {search}
      {viewAllListingsLink}
      {findASpaceLink}
      {pagesMenu}
      {createListingLink}
      {/* {Booking} */}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;

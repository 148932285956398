// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuLabel_root__3TkmG {\n  height: 100%;\n  width: 100%;\n  border: 0;\n  cursor: pointer;\n  background-color: transparent\n}\n\n.MenuLabel_root__3TkmG:hover {\n    background-color: transparent;\n    box-shadow: none;\n  }\n\n.MenuLabel_clickedWithMouse__31c0u:focus {\n    outline: none;\n  }\n\n/* Default isOpen class - no styles yet */\n\n.MenuLabel_isOpen__8HQrj {\n}\n", "",{"version":3,"sources":["webpack://src/components/MenuLabel/MenuLabel.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,SAAS;EACT,eAAe;EACf;AAMF;;AAJE;IACE,6BAA6B;IAC7B,gBAAgB;EAClB;;AAIA;IACE,aAAa;EACf;;AAGF,yCAAyC;;AACzC;AACA","sourcesContent":[".root {\n  height: 100%;\n  width: 100%;\n  border: 0;\n  cursor: pointer;\n  background-color: transparent;\n\n  &:hover {\n    background-color: transparent;\n    box-shadow: none;\n  }\n}\n\n.clickedWithMouse {\n  &:focus {\n    outline: none;\n  }\n}\n\n/* Default isOpen class - no styles yet */\n.isOpen {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MenuLabel_root__3TkmG",
	"clickedWithMouse": "MenuLabel_clickedWithMouse__31c0u",
	"isOpen": "MenuLabel_isOpen__8HQrj"
};
export default ___CSS_LOADER_EXPORT___;

import { fetchCurrentUser } from '../../ducks/user.duck';
import { getAvailablePlans } from '../../ducks/stripe.duck';

const initialState = {
  plans: [],
  plansLoaded: false
};

export default function princingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
}

// ================ Thunks ================ //

export const preparePlans = () => dispatch => {
  dispatch(getAvailablePlans());

  return dispatch(fetchCurrentUser()).then(() => {}).catch(e => console.log(e));
};

export const loadData = () => dispatch => {
  return dispatch(preparePlans());
};

import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldSelect, FieldTextInput, FieldRadioButton, FieldCurrencyInput } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './ApplicationForm.module.css';


const ApplicationFormComponent = props => {

    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    className,
                    disabled,
                    ready,
                    handleSubmit,
                    intl,
                    invalid,
                    pristine,
                    saveActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                    disabledApplication,
                    values,
                    showContactInformation
                } = formRenderProps;

                const accountTypes = findOptionsForSelectFilter('accountType', config.custom.userAccountType)

                // First name
                const firstNameLabel = intl.formatMessage({
                    id: 'ApplicationForm.firstNameLabel',
                });
                const firstNamePlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.firstNamePlaceholder',
                });

                // Last name
                const lastNameLabel = intl.formatMessage({
                    id: 'ApplicationForm.lastNameLabel',
                });
                const lastNamePlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.lastNamePlaceholder',
                });

                // Company name
                const companyNameLabel = intl.formatMessage({
                    id: 'ApplicationForm.companyNameLabel',
                });
                const companyNamePlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.companyNamePlaceholder',
                });

                // Address name 
                const addressLabel = intl.formatMessage({
                    id: 'ApplicationForm.addressLabel',
                });
                const addressPlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.addressPlaceholder',
                });

                // City name
                const cityLabel = intl.formatMessage({
                    id: 'ApplicationForm.cityLabel',
                });
                const cityPlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.cityPlaceholder',
                });

                // Zip code name
                const zipCodeLabel = intl.formatMessage({
                    id: 'ApplicationForm.zipCodeLabel',
                });
                const zipCodePlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.zipCodePlaceholder',
                });

                // Phone number name
                const phoneNumberLabel = intl.formatMessage({
                    id: 'ApplicationForm.phoneNumberLabel',
                });
                const phoneNumberPlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.phoneNumberPlaceholder',
                });

                //Email name
                const emailLabel = intl.formatMessage({
                    id: 'ApplicationForm.emailLabel',
                });
                const emailPlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.emailPlaceholder',
                });

                // Account Type name
                const accountTypeLabel = intl.formatMessage({
                    id: 'ApplicationForm.accountTypeLabel',
                });
                const accountTypePlaceholder = intl.formatMessage({
                    id: 'ApplicationForm.accountTypePlaceholder',
                });

                const requiredLabel = intl.formatMessage({
                    id: 'ApplicationForm.requiredLabel',
                });


                const { updateListingError, showListingsError } = fetchErrors || {};
                const errorMessageUpdateListing = updateListingError ? (
                    <p className={css.error}>
                        <FormattedMessage id="ApplicationForm.updateFailed" />
                    </p>
                ) : null;

                const errorMessageShowListing = showListingsError ? (
                    <p className={css.error}>
                        <FormattedMessage id="ApplicationForm.showListingFailed" />
                    </p>
                ) : null;

                const classes = classNames(css.root, className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;

                const { peopleAttending, chargeParticipants } = values || {};
                const showAsRequired = peopleAttending && chargeParticipants ? true : false;
                const submitDisabled = invalid || disabled || submitInProgress || !showAsRequired;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {errorMessageUpdateListing}
                        {errorMessageShowListing}

                        {showContactInformation && (
                            <>
                                <h3 className={css.subtitles}>
                                    Contact Information:
                                </h3>
                                <div className={css.twoContainer}>
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        label={firstNameLabel}
                                        placeholder={firstNamePlaceholder}
                                        disabled={true}
                                    />
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        label={lastNameLabel}
                                        placeholder={lastNamePlaceholder}
                                        disabled={true}
                                    />
                                </div>
                                <div className={css.twoContainer}>
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        label={phoneNumberLabel}
                                        placeholder={phoneNumberPlaceholder}
                                        disabled={true}
                                    />

                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="email"
                                        name="email"
                                        label={emailLabel}
                                        placeholder={emailPlaceholder}
                                        disabled={true}
                                    />

                                </div>
                                <FieldSelect
                                    className={css.input}
                                    type="text"
                                    id="accountType"
                                    name="accountType"
                                    label={accountTypeLabel}
                                    placeholder={accountTypePlaceholder}
                                    disabled={true}
                                >
                                    <option value="">Select Account Type</option>
                                    {accountTypes.map(accountType => {
                                        return (
                                            <option key={accountType.key} value={accountType.key}>
                                                {accountType.label}
                                            </option>
                                        );
                                    })}
                                </FieldSelect>
                                <div className={css.twoContainer}>
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="companyName"
                                        name="companyName"
                                        label={companyNameLabel}
                                        placeholder={companyNamePlaceholder}
                                        disabled={true}
                                    />
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="address"
                                        name="address"
                                        label={addressLabel}
                                        placeholder={addressPlaceholder}
                                        disabled={true}
                                    />
                                </div>
                                <div className={css.twoContainer}>
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="city"
                                        name="city"
                                        label={cityLabel}
                                        placeholder={cityPlaceholder}
                                        disabled={true}
                                    />
                                    <FieldTextInput
                                        className={css.twoInputs}
                                        type="text"
                                        id="zipCode"
                                        name="zipCode"
                                        label={zipCodeLabel}
                                        placeholder={zipCodePlaceholder}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        )}

                        <h3 className={css.subtitles}>
                            Please fill out the following section to learn more about your event:
                        </h3>

                        <div className={css.twoContainer}>
                            <FieldTextInput
                                className={css.twoInputs}
                                id="numberOfParticipants"
                                name="numberOfParticipants"
                                type="number"
                                placeholder="# of participants anticipated:"
                                label="# of participants anticipated:"
                                disabled={disabledApplication}
                                validate={composeValidators(required(requiredLabel))}
                            />

                            <FieldTextInput
                                className={css.twoInputs}
                                id="ages"
                                name="ages"
                                type="text"
                                placeholder="Ages:"
                                label="Ages:"
                                disabled={disabledApplication}
                                validate={composeValidators(required(requiredLabel))}
                            />
                        </div>
                            <div className={css.radioButtonContainer}>
                                <FieldRadioButton
                                    id={`applicationForm-peopleAttending1`}
                                    name="peopleAttending"
                                    label="Adults"
                                    value="a"
                                    className={css.radioButton}
                                    showAsRequired={!showAsRequired}
                                    disabled={disabledApplication}
                                />
                                <FieldRadioButton
                                    id={`applicationForm-peopleAttending2`}
                                    name="peopleAttending"
                                    label="Children"
                                    value="c"
                                    className={css.radioButton}
                                    showAsRequired={!showAsRequired}
                                    disabled={disabledApplication}
                                />
                                <FieldRadioButton
                                    id={`applicationForm-peopleAttending3`}
                                    name="peopleAttending"
                                    label="Both"
                                    value="b"
                                    className={css.radioButton}
                                    showAsRequired={!showAsRequired}
                                    disabled={disabledApplication}
                                />
                            </div>

                        <h3 className={css.subtitles}>
                            Organization information
                        </h3>

                        <FieldTextInput
                            className={css.input}
                            id="taxId"
                            name="taxId"
                            type="text"
                            placeholder="Tax ID #"
                            label="If you are a Non-Profit organization, enter your Tax ID#. Otherwise, enter N/A."
                            disabled={disabledApplication}
                        />

                        <h3 className={css.subtitles}>
                            Are you planning to charge your participants to attend the event?
                        </h3>

                        <div className={css.price}>
                            <div className={classNames(css.radioButtonContainer, css.twoThirdInput)}>
                                <FieldRadioButton
                                    id={`applicationForm-chargeParticipants1`}
                                    name="chargeParticipants"
                                    className={css.radioButton}
                                    label="Yes"
                                    value="y"
                                    showAsRequired={!showAsRequired}
                                    disabled={disabledApplication}
                                />
                                <FieldRadioButton
                                    id={`applicationForm-chargeParticipants2`}
                                    name="chargeParticipants"
                                    className={css.radioButton}
                                    label="No"
                                    value="n"
                                    showAsRequired={!showAsRequired}
                                    disabled={disabledApplication}
                                />
                            </div>
                            <FieldCurrencyInput
                                className={css.oneThirdInput}
                                id="price"
                                name="price"
                                placeholder="If yes, how much will you charge for the event"
                                label="If yes, how much will you charge for the event"
                                currencyConfig={config.currencyConfig}
                                disabled={disabledApplication}
                                validate={composeValidators(required(requiredLabel))}
                            />
                        </div>



                        <h3 className={css.subtitles}>
                            Any other information which you may wish to provide
                        </h3>

                        <FieldTextInput
                            className={css.moreInfo}
                            id="moreInfo"
                            name="moreInfo"
                            type="textarea"
                            placeholder="Explain your activity, details, etc..."
                            label="Explain your activity, details, etc..."
                            maxlength="250"
                            rows="2"
                            disabled={disabledApplication}
                            validate={composeValidators(required(requiredLabel))}
                        />

                        {!disabledApplication && (
                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}
                            >
                                {saveActionMsg}
                            </Button>
                        )}
                    </Form>
                );
            }}
        />
    )
};

ApplicationFormComponent.defaultProps = { className: null, fetchErrors: null };

ApplicationFormComponent.propTypes = {
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createListingDraftError: propTypes.error,
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    spaceTypeOptions: arrayOf(
        shape({
            key: string.isRequired,
            label: string.isRequired,
        })
    ),
};

export default compose(injectIntl)(ApplicationFormComponent);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuItem_root__EKhRf {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/MenuItem/MenuItem.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,SAAS;AACX","sourcesContent":[".root {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MenuItem_root__EKhRf"
};
export default ___CSS_LOADER_EXPORT___;

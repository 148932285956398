// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldTextInput_root__2ODuL {\n}\n\n.FieldTextInput_input__h1H0a {\n  color: var(--matterColorAnti);\n  background-color: var(--matterColorInput);\n  padding: 15px 16px;\n\n  margin-top: 12px;\n\n  border-radius: 3px;\n}\n\n.FieldTextInput_inputSuccess__30_Mn {\n  color: var(--matterColorDark);\n}\n\n.FieldTextInput_inputError__3NfYj {\n}\n\n.FieldTextInput_textarea__22x3U {\n}\n", "",{"version":3,"sources":["webpack://src/components/FieldTextInput/FieldTextInput.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,6BAA6B;EAC7B,yCAAyC;EACzC,kBAAkB;;EAElB,gBAAgB;;EAEhB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".root {\n}\n\n.input {\n  color: var(--matterColorAnti);\n  background-color: var(--matterColorInput);\n  padding: 15px 16px;\n\n  margin-top: 12px;\n\n  border-radius: 3px;\n}\n\n.inputSuccess {\n  color: var(--matterColorDark);\n}\n\n.inputError {\n}\n\n.textarea {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FieldTextInput_root__2ODuL",
	"input": "FieldTextInput_input__h1H0a",
	"inputSuccess": "FieldTextInput_inputSuccess__30_Mn",
	"inputError": "FieldTextInput_inputError__3NfYj",
	"textarea": "FieldTextInput_textarea__22x3U"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_logo__1Ev9K {\n    display: none\n}\n\n@media (min-width: 768px) {\n\n.Logo_logo__1Ev9K {\n        width: auto!important;\n        height: 64px!important;\n        display: block\n}\n    }\n\n.Logo_logoMobile__1jaq5 {\n    width: auto;\n    height: 55px\n}\n\n@media (min-width: 768px) {\n\n.Logo_logoMobile__1jaq5 {\n        display: none\n}\n    }\n\n", "",{"version":3,"sources":["webpack://src/components/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;IACI;AAOJ;;AALI;;AAHJ;QAIQ,qBAAqB;QACrB,sBAAsB;QACtB;AAER;IADI;;AAGJ;IACI,WAAW;IACX;AAIJ;;AAHI;;AAHJ;QAIQ;AAER;IADI","sourcesContent":[".logo {\n    display: none;\n\n    @media (--viewportMedium) {\n        width: auto!important;\n        height: 64px!important;\n        display: block;\n    }\n}\n\n.logoMobile {\n    width: auto;\n    height: 55px;\n    @media (--viewportMedium) {\n        display: none;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo_logo__1Ev9K",
	"logoMobile": "Logo_logoMobile__1jaq5"
};
export default ___CSS_LOADER_EXPORT___;

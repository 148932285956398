// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconAdd_root__tedyU {\n  fill: var(--marketplaceColor);\n}\n", "",{"version":3,"sources":["webpack://src/components/IconAdd/IconAdd.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":[".root {\n  fill: var(--marketplaceColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconAdd_root__tedyU"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Map_root__RzSUy {\n  width: 100%;\n  height: 100%;\n  background-color: var(--matterColorNegative);\n}\n\n.Map_mapRoot__P1_dA {\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Map/Map.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  background-color: var(--matterColorNegative);\n}\n\n.mapRoot {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Map_root__RzSUy",
	"mapRoot": "Map_mapRoot__P1_dA"
};
export default ___CSS_LOADER_EXPORT___;
